import { CaptionsAuthUser, UserPlanSchemaValues } from "~/context/CaptionsAuthContext";
import { getStatsigStore } from "~/stores/analytics/statsig";

import hasActiveSubscription from "../hasActiveSubscription";

function isUserFreeTier(user: CaptionsAuthUser | undefined): boolean {
  if (!user) {
    return false;
  }
  return user.subscription?.subscriptionTier == null;
}

function isUserMaxTier(user: CaptionsAuthUser | undefined): boolean {
  if (!user) {
    return false;
  }
  return user.subscription?.subscriptionTier === UserPlanSchemaValues.Max;
}

function isUserScaleTier(user: CaptionsAuthUser | undefined): boolean {
  if (!user) {
    return false;
  }
  return user.subscription?.subscriptionTier === UserPlanSchemaValues.Scale;
}

function isUserProTier(user: CaptionsAuthUser | undefined): boolean {
  if (!user) {
    return false;
  }
  return user.subscription?.subscriptionTier === UserPlanSchemaValues.Pro;
}

export async function canUserExportAd(user: CaptionsAuthUser | undefined): Promise<boolean> {
  if (!user) {
    return false;
  }
  const isAiExportsOnAllSubscriptionTiersEnabled = await getStatsigStore().getFeatureFlagAsync(
    "enable_ai_exports_on_all_subscription_tiers"
  );

  if (isAiExportsOnAllSubscriptionTiersEnabled) {
    return hasActiveSubscription(user);
  }
  return isUserMaxTier(user) || isUserScaleTier(user);
}

export async function canUserExportAiCreator(user: CaptionsAuthUser | undefined): Promise<boolean> {
  if (!user) {
    return false;
  }
  const isAiExportsOnAllSubscriptionTiersEnabled = await getStatsigStore().getFeatureFlagAsync(
    "enable_ai_exports_on_all_subscription_tiers"
  );

  if (isAiExportsOnAllSubscriptionTiersEnabled) {
    return hasActiveSubscription(user);
  }
  return isUserMaxTier(user) || isUserScaleTier(user);
}

export async function canUserExportAiEditVideo(
  user: CaptionsAuthUser | undefined
): Promise<boolean> {
  if (!user) {
    return false;
  }

  const isAiExportsOnAllSubscriptionTiersEnabled = await getStatsigStore().getFeatureFlagAsync(
    "enable_ai_exports_on_all_subscription_tiers"
  );

  if (isAiExportsOnAllSubscriptionTiersEnabled) {
    return hasActiveSubscription(user);
  }
  return isUserMaxTier(user) || isUserScaleTier(user);
}

export function canUserApplyEyeContact(user: CaptionsAuthUser | undefined): boolean {
  return !!user && hasActiveSubscription(user);
}

export function canUserExportAdsStudio(user: CaptionsAuthUser | undefined): boolean {
  return !!user && hasActiveSubscription(user);
}

export function canUserGenerateSocialStudio(user: CaptionsAuthUser | undefined): boolean {
  return !!user && hasActiveSubscription(user);
}

export function canUserGenerateAdsStudio(user: CaptionsAuthUser | undefined): boolean {
  return !!user && hasActiveSubscription(user);
}

export async function canUserGenerateConcurrentProjectsAiCreators(
  user: CaptionsAuthUser | undefined
): Promise<boolean> {
  const isAiExportsOnAllSubscriptionTiersEnabled = await getStatsigStore().getFeatureFlagAsync(
    "enable_ai_exports_on_all_subscription_tiers"
  );

  if (!isAiExportsOnAllSubscriptionTiersEnabled) {
    return true;
  }
  return isUserMaxTier(user) || isUserScaleTier(user);
}

export async function canUserGenerateConcurrentProjectsAiEdit(
  user: CaptionsAuthUser | undefined
): Promise<boolean> {
  const isAiExportsOnAllSubscriptionTiersEnabled = await getStatsigStore().getFeatureFlagAsync(
    "enable_ai_exports_on_all_subscription_tiers"
  );

  if (!isAiExportsOnAllSubscriptionTiersEnabled) {
    return true;
  }
  return isUserMaxTier(user) || isUserScaleTier(user);
}

export async function canUserSeeProjectListUpsell(
  user: CaptionsAuthUser | undefined
): Promise<boolean> {
  return isUserFreeTier(user) || isUserProTier(user);
}
