import type { CaptionStyle, VideoPixelMappings } from "captions-engine";
import { CaptionStylePreset } from "captions-engine";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { EXPORT_CHECKPOINT, EXPORT_TYPE_BACKEND } from "~/constants/mixpanel.constants";
import { useBackendServicesClient } from "~/context/BackendServicesContext";
import { ProjectFolderType } from "~/database/database.types";
import { useOnChangeEffect } from "~/hooks/helpers";
import { useAnalytics } from "~/hooks/useAnalytics";
import { SingleFileUploadStage, useSingleFileUpload } from "~/hooks/useSingleFileUpload";
import { project_exported, export_status, ProjectType } from "~/utils/analytics/ProductEvents";
import { sanitizeFileName } from "~/utils/sanitizeFileName";

import { ExportSettings } from "../components/ExportQualityDialog";
import { ExportCaptionFormat } from "../components/ExportQualityDialog/ExportCaptionsOptions.types";
import {
  getVideoOverlayJobStatus,
  StartOverlayJobRequest,
  startVideoOverlayJob,
  VideoExportAudioDubbing,
} from "../services/VideoOverlayJob";
import { createAPngEncoder } from "../utils/apngEncoder";
import { lerp } from "../utils/lerp";
import {
  renderOverlay,
  RenderOverlayAssets,
  RenderOverlayStatus,
  RenderOverlayStatusType,
  RenderOverlayVideoInfo,
} from "../utils/renderOverlay";

import { useReframe } from "./useReframe";
import { useWatermark } from "./useWatermark";

export enum OverlayEncoderStage {
  idle,
  rendering,
  packing,
  sending,
  processing,
  finished,
  error,
  canceled,
}

type OverlayRenderStageProgress = Record<OverlayEncoderStage, { min: number; max: number }>;

const PROGRESS_FACTORS: {
  video: OverlayRenderStageProgress;
  captions: OverlayRenderStageProgress;
} = {
  video: {
    [OverlayEncoderStage.idle]: { min: 0, max: 0 },
    [OverlayEncoderStage.rendering]: { min: 0, max: 60 },
    [OverlayEncoderStage.packing]: { min: 60, max: 60 },
    [OverlayEncoderStage.sending]: { min: 60, max: 80 },
    [OverlayEncoderStage.processing]: { min: 80, max: 100 },
    [OverlayEncoderStage.finished]: { min: 0, max: 0 },
    [OverlayEncoderStage.error]: { min: 0, max: 0 },
    [OverlayEncoderStage.canceled]: { min: 0, max: 0 },
  },
  captions: {
    [OverlayEncoderStage.idle]: { min: 0, max: 0 },
    [OverlayEncoderStage.rendering]: { min: 0, max: 100 },
    [OverlayEncoderStage.packing]: { min: 100, max: 100 },
    [OverlayEncoderStage.sending]: { min: 0, max: 0 },
    [OverlayEncoderStage.processing]: { min: 0, max: 0 },
    [OverlayEncoderStage.finished]: { min: 0, max: 0 },
    [OverlayEncoderStage.error]: { min: 0, max: 0 },
    [OverlayEncoderStage.canceled]: { min: 0, max: 0 },
  },
};

type OverlayEncoderExportTarget =
  | {
      target: "video";
    }
  | {
      target: "captions";
      format: ExportCaptionFormat;
    }
  | {
      target: "link";
    };

export type OverlayEncoderExportSettings = ExportSettings &
  OverlayEncoderExportTarget & { attempt?: number };

export interface OverlayEncoderProps {
  videoInfo: RenderOverlayVideoInfo;
  videoFileId: string | null;
  assets: RenderOverlayAssets;
  countryCode: string;
  projectName?: string;
  stylePreset?: CaptionStylePreset | null;
  style?: CaptionStyle | null;
  hideCaptions?: boolean;
  dubbingInfo?: VideoExportAudioDubbing;
  soundEffectInfo?: StartOverlayJobRequest["soundEffects"];
  eyeContactEnabled?: boolean;
  projectType?: ProjectFolderType | ProjectType;
  projectId?: string;
  projectFolderId?: string;
  isLandscape?: boolean;
  pixelMappings?: VideoPixelMappings[];
}

export function useOverlayEncoder({
  videoInfo,
  videoFileId,
  assets,
  countryCode,
  projectName,
  stylePreset,
  style,
  hideCaptions,
  dubbingInfo,
  soundEffectInfo,
  eyeContactEnabled,
  projectType,
  projectId,
  projectFolderId,
  pixelMappings,
  isLandscape,
}: OverlayEncoderProps) {
  const { targetWidth, targetHeight, targetAspect, reframe, offset, isSameAspect } = useReframe();

  const lastStateTimestamp = useRef<Date | null>(null);
  const exportClickTimestamp = useRef<Date | null>(null);

  const analytics = useAnalytics();
  const fileUpload = useSingleFileUpload();
  const [stage, setStage] = useState<OverlayEncoderStage>(OverlayEncoderStage.idle);
  const [stageProgress, setStageProgress] = useState<number>(0);
  const [stageTotal, setStageTotal] = useState<number>(0);
  const [assetFile, setAssetFile] = useState<File | null>(null);
  const [renderedFileURL, setRenderedFileURL] = useState<string | null>(null);
  const [renderedFileName, setRenderedFileName] = useState<string | null>(null);
  const [renderedFileSize, setRenderedFileSize] = useState<number | null>(null);
  const [renderedFileID, setRenderedFileID] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [videoOverlayJobId, setVideoOverlayJobId] = useState<string | null>(null);
  const [exportSettings, setExportSettings] = useState<OverlayEncoderExportSettings | null>(null);
  const client = useBackendServicesClient();
  const abortController = useRef(new AbortController());
  const currentProgress = useMemo(() => {
    if (!exportSettings) {
      return 0;
    }
    const progressFactors =
      exportSettings.target === "video" || exportSettings.target === "link"
        ? PROGRESS_FACTORS.video
        : PROGRESS_FACTORS.captions;
    const progressBoundaries = progressFactors[stage];
    const progress = lerp(
      progressBoundaries.min,
      progressBoundaries.max,
      stageProgress / (stageTotal || 1)
    );
    // rounds to 2 decimal places
    return Math.round(progress * 100) / 100;
  }, [stage, stageTotal, stageProgress, exportSettings?.target]);

  const watermark = useWatermark(projectType);

  const handleDownloadAssetFile = useCallback((file: File) => {
    const newUrl = URL.createObjectURL(file);
    setRenderedFileURL(newUrl);
    setRenderedFileSize(file.size);
    setRenderedFileName(file.name);
    setStage(OverlayEncoderStage.finished);
    setStageProgress(0);
    setStageTotal(0);
  }, []);

  // instrumentation
  useOnChangeEffect(stage, (stage) => {
    // track export status in mixpanel
    if (stage !== OverlayEncoderStage.idle) {
      const now = new Date();
      const timeSinceLastState = lastStateTimestamp.current
        ? now.getTime() - lastStateTimestamp.current.getTime()
        : null;
      const timeSinceExportClick = exportClickTimestamp.current
        ? now.getTime() - exportClickTimestamp.current.getTime()
        : null;
      lastStateTimestamp.current = now;
      const assetsCount = {
        audio: assets?.captionPages?.length,
        image: assets?.images?.length,
        video: assets.transitions.length,
      };

      const groupId: {
        ai_shorts_group_id?: string;
        ugc_ads_group_id?: string;
      } = {};
      if (projectType === "ai-shorts") {
        groupId.ai_shorts_group_id = projectFolderId;
      } else if (projectType === "ai-ads") {
        groupId.ugc_ads_group_id = projectFolderId;
      }

      analytics.track(
        ...export_status({
          ...groupId,
          attempt: exportSettings?.attempt,
          client_side_export: false,
          error: errorMessage,
          export_settings_fps: exportSettings?.fps,
          eye_contact_use: eyeContactEnabled,
          has_watermark: Boolean(watermark),
          latency: timeSinceExportClick,
          media_count: assets?.images?.length,
          overlay_generation_parameters: {
            assets_count: assetsCount,
            country_code: countryCode,
            hide_captions: hideCaptions,
          },
          project_id: projectId,
          project_type: projectType,
          reframe_aspect: targetAspect,
          reframe_height: reframe.dstHeight,
          reframe_pan: Boolean(offset.dx || offset.dy),
          reframe_scale: reframe.scale,
          reframe_width: reframe.dstWidth,
          relative_latency: timeSinceLastState,
          status: OverlayEncoderStage[stage],
          target: exportSettings?.target,
          transitions: assets.transitions.length,
          video_duration: videoInfo.duration,
          video_fps: videoInfo.fps,
          video_height: videoInfo.height,
          video_scale: videoInfo.scale,
          video_width: videoInfo.width,
        })
      );

      if (stage === OverlayEncoderStage.finished) {
        analytics.track(...project_exported());
      }
    }

    // expose error message to console on change (to be picked up by RUM)
    if (stage === OverlayEncoderStage.error && errorMessage) {
      console.error(errorMessage);
    }
  });

  const handleError = useCallback((error: unknown) => {
    if (abortController.current.signal.aborted) {
      setStage(OverlayEncoderStage.canceled);
    } else {
      setErrorMessage(error instanceof Error ? error.message : `${error}`);
      setStage(OverlayEncoderStage.error);
    }
  }, []);

  const cancelRender = useCallback(() => {
    abortController.current.abort();
  }, []);

  const handleRenderProgress = useCallback((status: RenderOverlayStatus) => {
    switch (status.type) {
      case RenderOverlayStatusType.started:
        setStage(OverlayEncoderStage.rendering);
        setStageProgress(0);
        setStageTotal(0);
        break;
      case RenderOverlayStatusType.renderingFrames:
        setStageProgress((frame) => Math.max(frame, status.currentFrame));
        setStageTotal(status.totalFrames);
        break;
      case RenderOverlayStatusType.finalizing:
        setStage(OverlayEncoderStage.packing);
        setStageProgress(0);
        setStageTotal(0);
    }
  }, []);

  const resetRender = useCallback(() => {
    if (
      ![
        OverlayEncoderStage.idle,
        OverlayEncoderStage.finished,
        OverlayEncoderStage.error,
        OverlayEncoderStage.canceled,
      ].includes(stage)
    ) {
      return;
    }
    setStage(OverlayEncoderStage.idle);
    setStageProgress(0);
    setStageTotal(0);
    setErrorMessage(null);
    setRenderedFileURL(null);
    setRenderedFileSize(null);
    setRenderedFileName(null);
    setRenderedFileID(null);
    abortController.current = new AbortController();
  }, [stage]);

  const startRender = useCallback(
    async (exportSettings: OverlayEncoderExportSettings, exportClickDate: Date | null) => {
      setExportSettings(exportSettings);
      const captionsOnly = exportSettings.target === "captions";
      const renderAssets: RenderOverlayAssets = captionsOnly
        ? {
            captionPages: assets.captionPages ?? [],
            transitions: [],
            watermark: assets.watermark,
          }
        : {
            captionPages: assets.captionPages ?? [],
            ...assets,
          };

      try {
        if (
          ![
            OverlayEncoderStage.idle,
            OverlayEncoderStage.finished,
            OverlayEncoderStage.error,
            OverlayEncoderStage.canceled,
          ].includes(stage)
        ) {
          return;
        }

        setStage(OverlayEncoderStage.idle);
        setStageProgress(0);
        setStageTotal(0);
        setErrorMessage(null);
        setRenderedFileName(null);
        abortController.current = new AbortController();
        if (!stylePreset || !style) {
          setErrorMessage("Caption style not provided");
          setStage(OverlayEncoderStage.error);
          return;
        }
        const startDate = new Date();
        lastStateTimestamp.current = exportClickDate;
        exportClickTimestamp.current = exportClickDate;
        const assetFileName = `assets-${sanitizeFileName(
          projectName ?? "unknown"
        )}-${startDate.toISOString()}.apng`;

        const data = await renderOverlay({
          videoInfo: {
            ...videoInfo,
            // The original video metadata has to be used when no reframing is performed
            // to properly deal with non-square pixels
            width: isSameAspect ? videoInfo.width : targetWidth,
            height: isSameAspect ? videoInfo.height : targetHeight,
            scale: isSameAspect ? videoInfo.scale : { x: 1, y: 1 },
            fps: exportSettings.fps ?? videoInfo.fps,
          },
          assets: renderAssets,
          countryCode,
          stylePreset,
          style,
          onStatus: handleRenderProgress,
          abortSignal: abortController.current.signal,
          encoderFactory: createAPngEncoder,
          hideCaptions,
          isLandscape,
          trackCaptionEngineState: (captionEngineState, status, error) =>
            analytics.track(
              EXPORT_CHECKPOINT,
              {
                checkpoint: captionEngineState,
                export_type: EXPORT_TYPE_BACKEND,
                project_id: projectId,
                status,
                error,
                video_width: videoInfo.width,
                video_height: videoInfo.height,
                target_width: targetWidth,
                target_height: targetHeight,
              },
              ["mixpanel"]
            ),
          trackEncodedFrame: (encodedFramePercent, status, error) =>
            analytics.track(
              EXPORT_CHECKPOINT,
              {
                checkpoint: encodedFramePercent,
                export_type: EXPORT_TYPE_BACKEND,
                project_id: projectId,
                status,
                error,
                video_width: videoInfo.width,
                video_height: videoInfo.height,
                target_width: targetWidth,
                target_height: targetHeight,
              },
              ["mixpanel"]
            ),
        });

        const assetFile = new File([data], assetFileName, { type: "image/apng" });
        setAssetFile(assetFile);
        if (exportSettings.target === "captions" && exportSettings.format === "apng") {
          handleDownloadAssetFile(assetFile);
          return;
        }
        await fileUpload.startUpload(assetFile, abortController.current);
      } catch (error) {
        handleError(error);
      }
    },
    [
      stage,
      videoInfo,
      countryCode,
      assets,
      stylePreset,
      handleRenderProgress,
      exportSettings,
      handleDownloadAssetFile,
      isSameAspect,
      targetWidth,
      targetHeight,
      pixelMappings,
      hideCaptions,
      isLandscape,
      projectName,
      style,
      fileUpload.startUpload,
      handleError,
      analytics,
    ]
  );

  const updateOverlayJobStatus = useCallback((videoExportJobId: string) => {
    getVideoOverlayJobStatus(client, videoExportJobId, abortController.current.signal)
      .then(({ job }) => {
        if (job.status === "started") {
          setTimeout(() => updateOverlayJobStatus(videoExportJobId), 500);
        } else if (job.status === "progress") {
          setStageTotal(100);
          setStageProgress(job.progress);
          setTimeout(() => updateOverlayJobStatus(videoExportJobId), 500);
        } else if (job.status === "finished") {
          setRenderedFileURL(job.outputDownloadUrl ?? null);
          setRenderedFileID(job.outputFileId ?? null);
          setRenderedFileSize(job.outputDownloadSize ?? null);
          setStage(OverlayEncoderStage.finished);
          setStageProgress(0);
          setStageTotal(0);
        } else if (job.status === "error") {
          setErrorMessage(job.errorMessage ?? "An error occurred while processing");
          setStage(OverlayEncoderStage.error);
        } else {
          console.error(job);
        }
      })
      .catch(handleError);
  }, []);

  useEffect(() => {
    if (fileUpload.stage === SingleFileUploadStage.uploading) {
      setStage(OverlayEncoderStage.sending);
      setStageProgress(0);
      setStageTotal(0);
    } else if (fileUpload.stage === SingleFileUploadStage.canceled) {
      setStage(OverlayEncoderStage.canceled);
    }
  }, [fileUpload.stage]);

  useEffect(() => {
    if (fileUpload.stage === SingleFileUploadStage.error) {
      setErrorMessage(fileUpload.error);
      setStage(OverlayEncoderStage.error);
    }
  }, [fileUpload.stage, fileUpload.error]);

  useEffect(() => {
    if (stage === OverlayEncoderStage.sending) {
      setStageTotal(fileUpload.totalSize);
      setStageProgress(fileUpload.uploadProgress);
    }
  }, [fileUpload.totalSize, fileUpload.uploadProgress, stage]);

  useEffect(() => {
    if (
      stage === OverlayEncoderStage.sending &&
      fileUpload.stage === SingleFileUploadStage.finished &&
      fileUpload.fileId &&
      videoFileId
    ) {
      const params = new URLSearchParams(window.location.search);
      const forceAppendOutro = params.get("forceAppendOutro");
      const appendOutro = !!forceAppendOutro;
      const shouldReframe = !isSameAspect;
      const globalReframe = shouldReframe ? reframe : undefined;
      setStage(OverlayEncoderStage.processing);
      setStageProgress(0);
      setStageTotal(0);

      startVideoOverlayJob(client, {
        inputFileId: videoFileId,
        // TODO: Fix endpoint needing the overlayFileId when no overlaying is needed
        overlayFileId: fileUpload.fileId,
        projectName,
        videoQuality: exportSettings?.videoQuality,
        fps: exportSettings?.fps,
        audioDubbing: dubbingInfo,
        soundEffects: soundEffectInfo,
        // TODO: Fix deleted clips support for the client side export
        trimVideoTimeInterval: exportSettings?.trimVideoTimeInterval,
        reframe: globalReframe,
        appendOutro,
      } satisfies StartOverlayJobRequest)
        .then((videoOverlayJob) => {
          setVideoOverlayJobId(videoOverlayJob.jobId);
          setTimeout(() => updateOverlayJobStatus(videoOverlayJob.jobId));
        })
        .catch(handleError);
    }
  }, [fileUpload.stage, fileUpload.fileId, stage, updateOverlayJobStatus]);

  useEffect(() => {
    const url = renderedFileURL;
    // Ensures that the blob URL is revoked when the component is unmounted
    // or when the URL changes
    if (url && url.startsWith("blob:")) {
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [renderedFileURL]);

  return {
    stage,
    currentProgress,
    assetFile,
    assetsFileId: fileUpload.fileId,
    renderedFileURL,
    renderedFileSize,
    renderedFileName,
    renderedFileID,
    errorMessage,
    videoOverlayJobId,
    startRender,
    resetRender,
    cancelRender,
  };
}
