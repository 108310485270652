import { APngEncoder } from "./apngEncoder";
import { APngEncoderBuilderWrapper } from "./apngEncoderBuilderWrapper";
import { APngEncoderWorkerWrapper } from "./apngEncoderWorkerWrapper";

/**
 * Creates a wrapper for the APngEncoder web worker if supported, with a legacy wrapper as a
 * fallback.
 *
 * @param width - the overall animation width in pixels.
 * @param height - the overall animation height in pixels.
 * @see APngEncoder.constructor
 * @see APngEncoderWorkerWrapper.constructor
 * @see APngEncoderBuilderWrapper.constructor
 */
export function createAPngEncoder(width: number, height: number): APngEncoder {
  if (APngEncoderWorkerWrapper.isSupported) {
    return new APngEncoderWorkerWrapper(width, height);
  } else {
    return new APngEncoderBuilderWrapper(width, height);
  }
}
