import { useEffect, useMemo } from "react";

import type { ProjectFaceData } from "~/modules/project/utils/faceData/face-data.types";
import { isSplitReframe, ShotReframeSplitLayoutType } from "~/utils/reframing";
import { autoReframeMemoized } from "~/utils/reframing/autoReframe";
import { resolveReframeOptions } from "~/utils/reframing/resolveReframeOptions";
import { splitShotHorizontal, splitShotVertical } from "~/utils/reframing/splitShot";
import { Clip, getAbsoluteTimestamp, getClipsFromTimestamp } from "~/utils/videoClips";

import type { useReframe } from "./useReframe";

export function usePerShotReframe(props: {
  clips: Clip[];
  handleClipsUpdating: (newClips: Clip[]) => void;
  faceData?: ProjectFaceData;
  reframeParams?: ReturnType<typeof useReframe>;
}) {
  const { clips, handleClipsUpdating, faceData, reframeParams } = props;

  const handleSplitScreenClick = (timestamp: number) => {
    // todo: analytics
    if (!reframeParams) {
      return;
    }
    let shotLayoutType: ShotReframeSplitLayoutType;
    if (reframeParams.targetAspect === "16_9") {
      shotLayoutType = "split-horizontal";
    } else if (reframeParams.targetAspect === "9_16") {
      shotLayoutType = "split-vertical";
    } else {
      return;
    }

    const absoluteTimestamp = getAbsoluteTimestamp(clips, timestamp);
    const clipsToAlter = getClipsFromTimestamp(clips, absoluteTimestamp);

    const newClips: Clip[] = clips.map((clip) => {
      const isPartOfCurrentClip = clipsToAlter.some((c) => c === clip);
      if (!isPartOfCurrentClip) {
        return clip;
      }

      // Toggle off
      if (isSplitReframe(clip.reframe)) {
        return {
          ...clip,
          reframe: {
            fitOrFill: clip.reframe?.fitOrFill ?? "fill",
            layout: {
              type: "custom-pan",
              offset: { dx: 0, dy: 0 },
            },
          },
        };
      }

      // Toggle on
      const splitShotParams = {
        shot: clip,
        faceData,
        videoSize: {
          outputWidth: reframeParams.targetWidth,
          outputHeight: reframeParams.targetHeight,
          originalWidth: reframeParams.originalWidth,
          originalHeight: reframeParams.originalHeight,
          targetAspectNum: reframeParams.targetAspectNum,
        },
      };

      return {
        ...clip,
        reframe: {
          fitOrFill: clip.reframe?.fitOrFill ?? "fill",
          layout: {
            type: shotLayoutType,
            rects:
              shotLayoutType === "split-horizontal"
                ? splitShotHorizontal(splitShotParams)
                : splitShotVertical(splitShotParams),
          },
        },
      };
    });
    handleClipsUpdating(newClips);
  };

  const handleFitOrFillClick = (fitOrFill: "fit" | "fill", timestamp: number) => {
    // todo: analytics
    const absoluteTimestamp = getAbsoluteTimestamp(clips, timestamp);
    const clipsToAlter = getClipsFromTimestamp(clips, absoluteTimestamp);
    const newClips: Clip[] = clips.map((clip) => {
      const isPartOfCurrentClip = clipsToAlter.some((c) => c === clip);
      if (!isPartOfCurrentClip) {
        return clip;
      }

      // Using "custom-pan" to prevent the shot being overridden by the auto-reframe logic
      return {
        ...clip,
        reframe: {
          fitOrFill,
          layout: { type: "custom-pan", offset: { dx: 0, dy: 0 } },
        },
      } satisfies Clip;
    });
    handleClipsUpdating(newClips);
  };

  const handleReframeOffsetChange = (
    offset: { dx: number; dy: number },
    currentClipIndex: number
  ) => {
    const newClips: Clip[] = clips.map((clip, index) => {
      const isCurrentClip = index === currentClipIndex;
      if (!isCurrentClip) {
        return clip;
      } else {
        return {
          ...clip,
          reframe: {
            fitOrFill: clip.reframe?.fitOrFill || "fill",
            layout: { type: "custom-pan", offset },
          },
        };
      }
    });
    handleClipsUpdating(newClips);
  };

  const resetPerShotReframe = () => {
    const newClips: Clip[] = clips.map((clip) => {
      return {
        ...clip,
        reframe: undefined,
      };
    });
    handleClipsUpdating(newClips);
  };

  // Reset all per-shot overrides when global options change
  useEffect(resetPerShotReframe, [reframeParams?.targetAspect, reframeParams?.containToCover]);

  const reframedShots = useMemo(() => {
    if (!reframeParams) {
      return clips;
    }
    const newShots = clips.map((shot) => {
      const options = resolveReframeOptions({ shot, reframeParams });
      if (options.shouldAutoReframe) {
        return {
          ...shot,
          reframe: autoReframeMemoized({
            shot,
            faceData,
            videoSize: {
              outputWidth: reframeParams.targetWidth,
              outputHeight: reframeParams.targetHeight,
              originalWidth: reframeParams.originalWidth,
              originalHeight: reframeParams.originalHeight,
              targetAspectNum: reframeParams.targetAspectNum,
            },
          }),
        };
      } else if (!options.shouldAutoReframe) {
        return {
          ...shot,
          reframe: options.resolved,
        };
      } else {
        return shot;
      }
    });
    // console.log({ newShots });
    return newShots;
  }, [
    clips,
    faceData,
    // individual reframeParams props listed separately to avoid unnecessary re-renders:
    reframeParams?.targetWidth,
    reframeParams?.targetHeight,
    reframeParams?.originalWidth,
    reframeParams?.targetAspectNum,
    reframeParams?.originalHeight,
    reframeParams?.containToCover,
    reframeParams?.isSameAspect,
  ]);

  return {
    reframedShots,
    handleFitOrFillClick,
    handleSplitScreenClick,
    handleReframeOffsetChange,
    resetPerShotReframe,
  };
}
