import type { Watermark } from "captions-engine";
import { useEffect, useState } from "react";

import { WATERMARK_SVG } from "~/constants/svglogo.constants";
import { useAuthState } from "~/context/CaptionsAuthContext";
import { ProjectFolderType } from "~/database/database.types";
import { ProjectType } from "~/utils/analytics/ProductEvents";
import { canUserExportAd } from "~/utils/permission/permission";
import { canUserExportAiCreator } from "~/utils/permission/permission";

let imagePromise: Promise<HTMLImageElement> | undefined;

/**
 * Get the logo image used for the watermark. Will only load the image once and reuse it if called
 * multiple times.
 *
 * @returns Promise that resolves with the logo image
 */
function getLogoImage(): Promise<HTMLImageElement> {
  if (imagePromise) {
    return imagePromise;
  }
  // Uses an inline SVG to avoid an extra network request and to ensure the watermark is always
  // available.
  const imageBlob = new Blob([WATERMARK_SVG], { type: "image/svg+xml" });
  const imageUrl = URL.createObjectURL(imageBlob);
  const image = new Image();
  imagePromise = new Promise((resolve, reject) => {
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
  });
  image.src = imageUrl;
  return imagePromise;
}

/**
 * Get the watermark to use for the project according to the project type and the user's
 * subscription tier.
 *
 * @param projectType - The type of project.
 * @param shouldEnableClientWatermark - Whether the client watermark should be enabled.
 * @returns The watermark to use for the project.
 */
export function useWatermark(
  projectType: ProjectFolderType | ProjectType | null | undefined,
  shouldEnableClientWatermark = true
): Watermark | null {
  const { user } = useAuthState();
  const [watermark, setWatermark] = useState<Watermark | null>(null);

  useEffect(() => {
    // Convert to async function
    const shouldShowWatermark = async () => {
      if (projectType === "ai-ads") {
        const canExport = await canUserExportAd(user);
        return !canExport && shouldEnableClientWatermark;
      } else if (projectType === "ai-avatar") {
        const canExport = await canUserExportAiCreator(user);
        return !canExport && shouldEnableClientWatermark;
      }

      // For debug/testing purposes
      const params = new URLSearchParams(window.location.search);
      return !!params.get("forceMovingWatermark");
    };

    const initWatermark = async () => {
      const shouldShow = await shouldShowWatermark();

      if (!shouldShow) {
        setWatermark(null);
        return;
      }

      try {
        const image = await getLogoImage();
        setWatermark({
          mode: projectType === "ai-ads" ? "tiled" : "moving",
          image,
        });
      } catch (error) {
        // NOTE: This is never expected to fail as the image is not loaded from an external source.
        // This is here for debugging purposes.
        console.error(error);
      }
    };

    initWatermark();
  }, [user, projectType, shouldEnableClientWatermark]);

  return watermark;
}
