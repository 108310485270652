import type { CaptionsAuthUser } from "~/context/CaptionsAuthContext";

const hasActiveSubscription = (user: CaptionsAuthUser) => {
  return (
    typeof user?.subscription?.renewalDateMillis === "number" &&
    new Date(user.subscription.renewalDateMillis) > new Date()
  );
};

export default hasActiveSubscription;
