import { AnyCanvasRenderingContext2D, CaptionVideoArea } from "captions-engine";

// TODO: Since we don't have the outer box padding anymore, check if we can remove this variable.
export const OUTER_BOX_PADDING = 0;
export const VERTEX_SIZE = 8;
const HALF_OUTER_BOX_PADDING = OUTER_BOX_PADDING / 2;

function drawVertices(
  context: AnyCanvasRenderingContext2D,
  selectionBoxSizeFactor: number,
  startX: number,
  startY: number,
  outerBoxWidth: number,
  outerBoxHeight: number,
  halfOuterBoxPadding: number
) {
  const vertexSize = selectionBoxSizeFactor * VERTEX_SIZE;
  const vertexOffset = vertexSize / 2;
  const vertexRadius = selectionBoxSizeFactor;
  const leftXCoord = startX - halfOuterBoxPadding - vertexOffset;
  const rightXCoord = startX + outerBoxWidth + halfOuterBoxPadding - vertexOffset;
  const topYCoord = startY - halfOuterBoxPadding - vertexOffset;
  const bottomYCoord = startY + outerBoxHeight + halfOuterBoxPadding - vertexOffset;
  const middleXCoord = startX + outerBoxWidth / 2 - vertexOffset;
  const middleYCoord = startY + outerBoxHeight / 2 - vertexOffset;

  context.roundRect(leftXCoord, topYCoord, vertexSize, vertexSize, vertexRadius);
  context.roundRect(middleXCoord, topYCoord, vertexSize, vertexSize, vertexRadius);
  context.roundRect(rightXCoord, topYCoord, vertexSize, vertexSize, vertexRadius);
  context.roundRect(leftXCoord, middleYCoord, vertexSize, vertexSize, vertexRadius);
  context.roundRect(rightXCoord, middleYCoord, vertexSize, vertexSize, vertexRadius);
  context.roundRect(leftXCoord, bottomYCoord, vertexSize, vertexSize, vertexRadius);
  context.roundRect(middleXCoord, bottomYCoord, vertexSize, vertexSize, vertexRadius);
  context.roundRect(rightXCoord, bottomYCoord, vertexSize, vertexSize, vertexRadius);
}

export function drawSelectionBox({
  context,
  selectionBoxSizeFactor,
  startX,
  startY,
  outerBoxHeight,
  outerBoxWidth,
  rotation,
  videoArea,
  renderVertices,
}: {
  context: AnyCanvasRenderingContext2D;
  selectionBoxSizeFactor?: number;
  startX: number;
  startY: number;
  outerBoxWidth: number;
  outerBoxHeight: number;
  rotation: number;
  videoArea?: CaptionVideoArea;
  renderVertices?: boolean;
}) {
  selectionBoxSizeFactor ??= 1;
  context.save();
  context.beginPath();
  if (videoArea) {
    context.translate(videoArea.x, videoArea.y);
    context.scale(1 / videoArea.scale.x, 1 / videoArea.scale.y);
  }
  context.translate(startX * (videoArea?.scale.x ?? 1), startY * (videoArea?.scale.y ?? 1));
  context.rotate(rotation);

  const outerBoxStartX = -(outerBoxWidth / 2);
  const outerBoxStartY = -(outerBoxHeight / 2);
  context.strokeStyle = "#7E12FF";
  context.fillStyle = "#ffffff";
  context.lineWidth = 4 * selectionBoxSizeFactor;
  context.shadowBlur = 0;
  context.shadowColor = "transparent";

  const selectionBoxX = outerBoxStartX - HALF_OUTER_BOX_PADDING;
  const selectionBoxY = outerBoxStartY - HALF_OUTER_BOX_PADDING;
  const selectionBoxWidth = outerBoxWidth + OUTER_BOX_PADDING;
  const selectionBoxHeight = outerBoxHeight + OUTER_BOX_PADDING;

  context.strokeRect(selectionBoxX, selectionBoxY, selectionBoxWidth, selectionBoxHeight);

  if (renderVertices) {
    drawVertices(
      context,
      selectionBoxSizeFactor,
      outerBoxStartX,
      outerBoxStartY,
      outerBoxWidth,
      outerBoxHeight,
      HALF_OUTER_BOX_PADDING
    );
  }

  context.stroke();
  context.fill();
  context.restore();

  return {
    selectionBoxX,
    selectionBoxY,
    selectionBoxWidth,
    selectionBoxHeight,
  };
}

export function clearOutside(
  ctx: AnyCanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number
) {
  ctx.clearRect(0, 0, x, ctx.canvas.height);
  ctx.clearRect(x + width, 0, ctx.canvas.width - x - width, ctx.canvas.height);
  ctx.clearRect(x, 0, width, y);
  ctx.clearRect(x, y + height, width, ctx.canvas.height - y - height);
}
