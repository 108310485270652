import { useCallback, useMemo, useState } from "react";

import { useToast } from "~/components";
import { useStandardDialogs } from "~/context/StandardDialogsContext";
import { Effects } from "~/database/database.types";
import { Transition } from "~/database/transition.types";
import { ShotReframeOptions } from "~/utils/reframing";
import { roundTimestampWithoutRiskingPrecisionLoss } from "~/utils/timestamp";
import {
  Clip,
  ClipTuple,
  getAbsoluteTimestamp,
  getRelativeTimestamp,
  mergeClipsWithAbsoluteTimestamps,
} from "~/utils/videoClips";

import { CaptionWord } from "../utils/captionProcessing";
import type { ProjectFaceData } from "../utils/faceData/face-data.types";
import { convertTransitionsToShots } from "../utils/shots";

import { usePerShotReframe } from "./usePerShotReframe";
import type { useReframe } from "./useReframe";

export interface ClipSplittingProps {
  faceData?: ProjectFaceData;
  reframeParams?: ReturnType<typeof useReframe>;
}

export interface ClipWithAbsoluteTimestamps extends Clip {
  absoluteStartTime: number;
  absoluteEndTime: number;
  deleted?: boolean;
}

export interface NonDeletedClip extends Omit<ClipWithAbsoluteTimestamps, "deleted"> {
  deleted: false;
}

export interface RemainingClip {
  startTime: number;
  endTime: number;
  absoluteTimestamps: { startTime: number; endTime: number }[];
  reframe?: ShotReframeOptions;
}

export const convertToTuple = (clip: Clip): ClipTuple => [clip.startTime, clip.endTime];

/**
 * Manages split/delete state
 */
export function useClipSplitting({ faceData, reframeParams }: ClipSplittingProps = {}) {
  // single source of truth
  const [clips, setClips] = useState<Clip[]>([]);
  const { confirmDialog } = useStandardDialogs();
  const { add: addToast } = useToast();

  const handleClipsUpdating = useCallback((newClips: Clip[]) => {
    setClips(newClips);
  }, []);

  const perShotReframe = usePerShotReframe({
    clips,
    handleClipsUpdating,
    faceData,
    reframeParams,
  });

  // derived values
  // todo: convert these interfaces to use `Clip` type also (used many places)
  const deletedClips: ClipTuple[] = useMemo(
    () => perShotReframe.reframedShots.filter((clip) => clip.deleted).map(convertToTuple),
    [perShotReframe.reframedShots]
  );

  const nonDeletedClipsWithRelativeTimestamps: ClipWithAbsoluteTimestamps[] = useMemo(
    () =>
      perShotReframe.reframedShots
        .filter((clip) => !clip.deleted)
        .map((item) => {
          return {
            startTime: getRelativeTimestamp(perShotReframe.reframedShots, item.startTime),
            endTime: getRelativeTimestamp(perShotReframe.reframedShots, item.endTime),
            absoluteStartTime: item.startTime,
            absoluteEndTime: item.endTime,
            deleted: false,
            mergeWithNext: item.mergeWithNext,
            reframe: item.reframe,
          };
        }),
    [perShotReframe.reframedShots]
  );

  const remainingClips = useMemo<RemainingClip[]>(
    () => mergeClipsWithAbsoluteTimestamps(nonDeletedClipsWithRelativeTimestamps),
    [nonDeletedClipsWithRelativeTimestamps]
  );

  const clippingPositions: number[] = useMemo(
    () => clips.slice(0, -1).map(({ endTime }) => endTime),
    [clips]
  );

  /**
   * Insert a new clipping position into the list of clipping positions
   *
   * @param timestamp - the video timestamp where the clip should be split
   * @param duration - the duration of the video, in seconds
   */
  const addClippingPosition = (timestamp: number, duration: number) =>
    setClips((clips) => {
      const position = getAbsoluteTimestamp(clips, timestamp);
      if (clips.length === 0) {
        return [
          { startTime: 0, endTime: position },
          { startTime: position, endTime: duration },
        ];
      }

      const CLIPPING_THRESHOLD = 0.01;

      return clips.flatMap((clip) =>
        position - CLIPPING_THRESHOLD > clip.startTime &&
        position + CLIPPING_THRESHOLD < clip.endTime
          ? [
              { startTime: clip.startTime, endTime: position },
              { startTime: position, endTime: clip.endTime },
            ]
          : [clip]
      );
    });

  const removeClippingPosition = (_position: number) => {
    // completely disabled for now
    // or, we can support "undeletion" here
  };

  /** Reset the list of clipping positions to the default state (a single clip) */
  const resetClippingPositions = useCallback(() => {
    setClips([]);
  }, []);

  /** Delete a clip matching the `startTime` and `endTime` of the given one. */
  const deleteClip = useCallback((clipToDelete: Clip) => {
    setClips((clips) => {
      return clips.map((clip) => {
        if (clip.startTime === clipToDelete.startTime && clip.endTime === clipToDelete.endTime) {
          return { ...clip, deleted: true };
        }
        return clip;
      });
    });
  }, []);

  /**
   * Performs the "split clip" operation at a particular timestamp.
   *
   * @param words the caption words array, for snapping to the closest one
   * @param position absolute video timestamp for the split, in seconds
   * @param duration the total duration of the video, in seconds
   * @param callback optional callback with the timestamp that was snapped to
   */
  const handleClipSplitting = (
    words: Array<CaptionWord> | null,
    position: number,
    duration: number,
    callback?: (newTimestamp: number) => void
  ) => {
    // TODO: consider extracting into a util and unit testing
    const closestWord = words?.find(
      (word) => word.startTime <= position && word.endTime > position
    );

    // TODO: consider extracting into a util and unit testing
    const closestWordClosestTime = closestWord
      ? closestWord.endTime - position < position - closestWord.startTime
        ? closestWord.endTime
        : closestWord.startTime
      : position;

    const splittingAtStartTime = closestWordClosestTime === 0;
    const splittingAtFirstClip = closestWordClosestTime === remainingClips[0]?.startTime;
    const splittingAtEndTime = closestWordClosestTime === duration;

    if (splittingAtStartTime || splittingAtFirstClip || splittingAtEndTime) {
      return;
    }

    // TODO: consider extracting into a util and unit testing
    const NEARBY_CLIP_THRESHOLD_SECONDS = 0.01;
    const nearestClipIndex = clippingPositions.findIndex(
      (clipPosition) =>
        closestWordClosestTime > clipPosition - NEARBY_CLIP_THRESHOLD_SECONDS &&
        closestWordClosestTime < clipPosition + NEARBY_CLIP_THRESHOLD_SECONDS
    );
    const hasNearbyClip = nearestClipIndex > -1;
    if (hasNearbyClip) {
      removeClippingPosition(clippingPositions[nearestClipIndex]);
    } else {
      addClippingPosition(closestWordClosestTime, duration);
    }

    if (callback) {
      callback(closestWordClosestTime);
    }
  };

  /**
   * Performs the "delete clip" operation at a particular timestamp.
   *
   * @param timestamp video timestamp, in seconds, within the clip to delete
   * @param callback optional callback with the clip that was deleted
   */
  const handleClipDeleting = async (timestamp: number, callback?: (clip: ClipTuple) => void) => {
    if (remainingClips.length === 1) {
      addToast("Unable to delete last clip. A project must have at least one clip.", {
        severity: "error",
        duration: 5000,
      });
      return;
    }

    const position = getAbsoluteTimestamp(clips, timestamp);
    const clipsToDelete: Clip[] = [];
    remainingClips.forEach((clip) => {
      if (
        position >= clip.absoluteTimestamps[0].startTime &&
        position < clip.absoluteTimestamps[clip.absoluteTimestamps.length - 1].endTime
      ) {
        clip.absoluteTimestamps.forEach(({ startTime, endTime }) => {
          clipsToDelete.push({
            startTime,
            endTime,
          });
        });
      }
    });

    if (!clipsToDelete.length) {
      return;
    }
    const relativeClipsToDelete = {
      startTime: getRelativeTimestamp(clips, clipsToDelete[0].startTime),
      endTime: getRelativeTimestamp(clips, clipsToDelete[clipsToDelete.length - 1].endTime),
    };

    const shouldDelete = await confirmDialog("This will permanently delete the selected shot.", {
      title: "Delete Shot?",
      type: "delete",
    });
    if (!shouldDelete) {
      return;
    }
    clipsToDelete.forEach((clip) => deleteClip(clip));
    callback?.(convertToTuple(relativeClipsToDelete));
  };

  const handleShotsMerging = (
    transitionId: string,
    transitions: Transition[],
    callback: (clips: Clip[]) => void
  ) => {
    const transition = transitions.find(({ id }) => id === transitionId);
    const transitionTime = transition?.startTime;
    if (transitionTime === undefined) {
      return;
    }
    const clipToUpdate: ClipWithAbsoluteTimestamps | undefined =
      nonDeletedClipsWithRelativeTimestamps.find((clip) => {
        return (
          roundTimestampWithoutRiskingPrecisionLoss(clip.endTime) ===
          roundTimestampWithoutRiskingPrecisionLoss(transitionTime)
        );
      });
    const clipsUpdated = clips.map((clip) => {
      if (clip.endTime === clipToUpdate?.absoluteEndTime) {
        return {
          ...clip,
          mergeWithNext: true,
        };
      }
      return clip;
    });
    callback(clipsUpdated);
    handleClipsUpdating(clipsUpdated);
  };

  /** Re-hydrates the React state for `clips` from the database. */
  const restoreFromEffectsEntity = (effects: Effects, transitions: Transition[] | undefined) => {
    const clipsFromTransitions = transitions
      ? convertTransitionsToShots(transitions, effects.clips)
      : [];

    if (effects?.clips && clipsFromTransitions.length > effects.clips.length) {
      setClips(clipsFromTransitions);
    } else {
      setClips(effects?.clips || []);
    }
  };

  return {
    clips: perShotReframe.reframedShots,
    deletedClips,
    remainingClips,
    addClippingPosition,
    handleClipDeleting,
    handleShotsMerging,
    handleClipSplitting,
    handleClipsUpdating,
    restoreFromEffectsEntity,
    resetClippingPositions,
    handleFitOrFillClick: perShotReframe.handleFitOrFillClick,
    handleSplitScreenClick: perShotReframe.handleSplitScreenClick,
    handleReframeOffsetChange: perShotReframe.handleReframeOffsetChange,
    resetPerShotReframe: perShotReframe.resetPerShotReframe,
  };
}
