import type { AnyCanvasRenderingContext2D } from "captions-engine";

import {
  EncodeCaptionAPngMessage,
  EncodeCaptionAPngResponse,
} from "../../workers/encodeCaptionApng";

import { APngEncoder } from "./apngEncoder";

const BASE_TIMEOUT = 20000;
const POLL_INTERVAL = 100;
const MAX_QUEUE_FRAMES = 10;

/**
 * Class that abstracts using APngEncoder on a separate Web Worker
 */
export class APngEncoderWorkerWrapper extends APngEncoder {
  private readonly worker: Worker;
  private readonly apngJobId: string;
  private pendingError: Error | undefined;
  private lastEncodedFrame = -1;
  private lastSentFrame = -1;
  private encodedData: Uint8Array | null = null;

  /**
   * Constructs a new APngEncoderWorkerWrapper instance, starts the web worker and signals it to
   * create an APngEncoder object.
   *
   * @param width - the overall animation width in pixels.
   * @param height - the overall animation height in pixels.
   * @see APngEncoder.constructor
   */
  constructor(width: number, height: number) {
    super(width, height);
    this.worker = new Worker(
      new URL("../../workers/encodeCaptionApng/encodeCaptionApng", import.meta.url)
    );
    this.worker.onerror = (errorEvent) => this.handleErrorEvent(errorEvent);
    this.worker.onmessageerror = () => this.handleMessageErrorEvent();
    this.worker.onmessage = (event) => this.handleMessageEvent(event);
    this.apngJobId = `${Math.random()}`;
    this.postMessage({
      name: "start",
      apngJobId: this.apngJobId,
      width: width,
      height: height,
    });
  }

  /**
   * Signals the APngEncoder worker to add a new frame to the animation. Will wait if there are
   * more than MAX_QUEUE_FRAMES frames waiting to be processed.
   *
   * @param frameImage - the image to be encoded.
   * @param timestampUs - the timestamp in microseconds.
   * @see APngEncoder.addFrame
   */
  async addFrame(frameImage: AnyCanvasRenderingContext2D, timestampUs: number) {
    await this.waitWorker(() => this.lastSentFrame < this.lastEncodedFrame + MAX_QUEUE_FRAMES);
    const image = frameImage.getImageData(0, 0, this.width, this.height);
    this.lastSentFrame = this.lastSentFrame + 1;
    this.postMessage(
      {
        name: "add",
        apngJobId: this.apngJobId,
        frameId: this.lastSentFrame,
        timestamp: timestampUs,
        image,
      },
      { transfer: [image.data.buffer] }
    );
  }

  /**
   * Signals the APngEncoder worker to finalize the animation, waits for it to process it and
   * returns the encoded file.
   *
   * @param durationUs - the total running time of the animation in
   * microseconds.
   * @return the encoded animation.
   * @see APngEncoder.getEncoded
   */
  async getEncoded(durationUs: number): Promise<Uint8Array> {
    if (this.encodedData) {
      return this.encodedData;
    }
    this.postMessage({
      name: "finalize",
      apngJobId: this.apngJobId,
      duration: durationUs,
    });
    await this.waitWorker(() => !!this.encodedData);
    if (!this.encodedData) {
      throw new Error("Unexpected error");
    }
    return this.encodedData;
  }

  /**
   * Abruptly terminates the underlying web worker thread.
   * @see Worker.terminate
   */
  terminate() {
    this.worker.terminate();
  }

  /**
   * Waits for a certain condition to be true, pooling every POLL_INTERVAL milliseconds. This will
   * allow the web worker to catch up, while checking for returned errors, throwing them if any.
   * If more than BASE_TIMEOUT passes without fulfilling the condition or a new frame being
   * rendered, throws an exception.
   *
   * @param condition - function that returns the condition that will be checked.
   * @private
   */
  private async waitWorker(condition: () => boolean) {
    if (this.pendingError) {
      throw this.pendingError;
    }
    let timeout = 0;
    let previousEncodedFrame = this.lastEncodedFrame;
    while (!condition()) {
      await new Promise((resolve) => setTimeout(resolve, POLL_INTERVAL));
      if (this.lastEncodedFrame !== previousEncodedFrame) {
        timeout = 0;
        previousEncodedFrame = this.lastEncodedFrame;
      } else {
        timeout = timeout + POLL_INTERVAL;
      }
      if (timeout >= BASE_TIMEOUT) {
        throw new Error("Stream encoder not responding");
      }
      if (this.pendingError) {
        throw this.pendingError;
      }
    }
  }

  private postMessage(message: EncodeCaptionAPngMessage, options?: StructuredSerializeOptions) {
    this.worker.postMessage(message, options);
  }

  private handleErrorEvent(errorEvent: ErrorEvent) {
    this.pendingError = new Error(errorEvent.message ?? "No details available");
  }

  private handleMessageErrorEvent() {
    this.pendingError = new Error("Failed to deserialize worker data");
  }

  private handleMessageEvent(event: MessageEvent<EncodeCaptionAPngResponse>) {
    if (event.data.apngJobId !== this.apngJobId) {
      return;
    }
    if (event.data.name === "added") {
      this.lastEncodedFrame = event.data.frameId;
      this.onFrameArrived?.(this.lastEncodedFrame);
    } else if (event.data.name === "encoded") {
      this.encodedData = event.data.encodedData;
    }
  }

  static get isSupported() {
    return typeof self.OffscreenCanvas !== "undefined";
  }
}
