import { satisfies } from "compare-versions";
import { useEffect, useState } from "react";

const commonVersionIdentifier = /version\/(\d+(\.?_?\d+)+)/i;

export type BrowserType =
  | "Opera"
  | "Microsoft Edge"
  | "Google Chrome"
  | "Mozilla Firefox"
  | "Apple Safari"
  | "Microsoft Internet Explorer"
  | "UC Browser"
  | "Samsung Browser"
  | "Unknown browser";

const VERSION_MATCHERS = {
  Opera: /opr\/(\d+(?:[.|_]\w+)+)/i,
  "Microsoft Edge": /edg\/(\d+(?:[.|_]\w+)+)/i,
  "Google Chrome": /(?:chrome|chromium|crios)\/(\d+(?:[.|_]\w+)+)/i,
  "Mozilla Firefox": /firefox\/(\d+(?:[.|_]\w+)+)/i,
  "Apple Safari": /version\/(\d+(?:[.|_]\w+)+)/i,
  "Microsoft Internet Explorer": /(?:msie\s|rv:)(\d+(?:[.|_]\w+)+)[;)]/i,
  "UC Browser": /ucbrowser\/(\d+(?:[.|_]\w+)+)/i,
  "Samsung Browser": /samsungbrowser\/(\d+(?:[.|_]\w+)+)/i,
  "Unknown browser": undefined,
};

export function getBrowserType(): BrowserType {
  const test = (regexp: RegExp) => {
    return regexp.test(navigator.userAgent);
  };

  if (test(/opr\//i)) {
    return "Opera";
  } else if (test(/edg/i)) {
    return "Microsoft Edge";
  } else if (test(/chrome|chromium|crios/i)) {
    return "Google Chrome";
  } else if (test(/firefox|fxios/i)) {
    return "Mozilla Firefox";
  } else if (test(/safari/i)) {
    return "Apple Safari";
  } else if (test(/trident/i)) {
    return "Microsoft Internet Explorer";
  } else if (test(/ucbrowser/i)) {
    return "UC Browser";
  } else if (test(/samsungbrowser/i)) {
    return "Samsung Browser";
  } else {
    return "Unknown browser";
  }
}

export function getBrowserVersion(browserType: BrowserType): string | undefined {
  const { navigator } = window ?? {};
  const versionMatcher = VERSION_MATCHERS[browserType];
  if (navigator.userAgent && versionMatcher) {
    const versionMatch = navigator.userAgent.match(versionMatcher);
    return versionMatch?.[1];
  }
}

export function useSupportedBrowserVersion(): boolean {
  const [isSupported, setIsSupported] = useState(true);

  useEffect(() => {
    const { navigator } = window ?? {};
    if (navigator.userAgent) {
      const browserType = getBrowserType();

      if (browserType === "Apple Safari") {
        const versionMatcher = window.navigator.userAgent.match(commonVersionIdentifier);
        const version = versionMatcher?.[1];
        if (version && satisfies(version, "<17.0.0")) {
          setIsSupported(false);
        }
      }
    }
  }, []);

  return isSupported;
}
