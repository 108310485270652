import { ProjectFaceData } from "~/modules/project/utils/faceData/face-data.types";

import type { Clip } from "../videoClips";

import type { ShotReframeOptions } from "./reframing.types";

export function centerFace(args: {
  shot: Clip;
  faceData: ProjectFaceData | undefined;
  videoSize: {
    outputWidth: number;
    outputHeight: number;
    originalWidth: number;
    originalHeight: number;
    targetAspectNum: number;
  };
}): ShotReframeOptions | undefined {
  const { shot, faceData, videoSize } = args;

  if (!faceData) {
    return undefined;
  }

  const centerPoints: { x: number; y: number }[] = [];
  faceData.faceFrames.forEach((faces, i) => {
    const timestamp = i / faceData.fps;

    // Select only face timestamps within the shot
    // Both `faceData` and `shot` are using absolute timestamps
    const timestampWithinShot = timestamp >= shot.startTime && timestamp <= shot.endTime;
    if (!timestampWithinShot) {
      return;
    }

    // Get largest face
    let largestFace: [number, number, number, number] | undefined;
    for (const face of faces) {
      const [x, y, width, height] = face;
      const area = width * height;
      const largestArea = largestFace ? largestFace[2] * largestFace[3] : 0;
      if (!largestFace || area > largestArea) {
        largestFace = [x, y, width, height];
      }
    }
    if (!largestFace) {
      return;
    }

    // Find center point
    const centerPoint = {
      x: largestFace[0] + largestFace[2] / 2,
      y: largestFace[1] + largestFace[3] / 2,
    };
    centerPoints.push(centerPoint);
  });

  // Average the center points
  if (centerPoints.length === 0) {
    return undefined;
  }
  const faceCenter = {
    x: centerPoints.reduce((sum, { x }) => sum + x, 0) / centerPoints.length,
    y: centerPoints.reduce((sum, { y }) => sum + y, 0) / centerPoints.length,
  };

  // Note: `faceCenter` is relative to the TOP LEFT of the original video
  // Transform it to be relative to the CENTER of the original video
  const faceCenterChangeOfOrigin = {
    x: faceCenter.x - videoSize.originalWidth / 2,
    y: faceCenter.y - videoSize.originalHeight / 2,
  };

  // Scale the original video to fill entire screen
  const scaleFill = Math.max(
    videoSize.outputWidth / videoSize.originalWidth,
    videoSize.outputHeight / videoSize.originalHeight
  );
  const scaledFaceCenter = {
    x: faceCenterChangeOfOrigin.x * scaleFill,
    y: faceCenterChangeOfOrigin.y * scaleFill,
  };

  // Since the video center is the origin, just invert x and y to get the offset
  // (a vector pointing from the face center to the video center)
  const localOffset = {
    dx: -scaledFaceCenter.x,
    dy: -scaledFaceCenter.y,
  };

  // map local offsets to persisted ones with limits on view
  // (taken from `VideoPlayerPlaybackArea`)
  let dx = 0;
  let dy = 0;
  const scaleToView = scaleFill;
  const originalAspect = videoSize.originalWidth / videoSize.originalHeight;
  if (videoSize.targetAspectNum < originalAspect) {
    dx = localOffset.dx / videoSize.originalWidth / scaleToView;
    const dxLimit = 0.5 - videoSize.outputWidth / (videoSize.originalWidth * scaleToView) / 2;
    dx = Math.min(dxLimit, Math.max(-dxLimit, dx));
  }

  if (videoSize.targetAspectNum > originalAspect) {
    dy = localOffset.dy / videoSize.originalHeight / scaleToView;
    const dyLimit = 0.5 - videoSize.outputHeight / (videoSize.originalHeight * scaleToView) / 2;
    dy = Math.min(dyLimit, Math.max(-dyLimit, dy));
  }

  // Deliver a complete reframe options object
  const reframe: ShotReframeOptions = {
    fitOrFill: "fill",
    layout: {
      type: "center-face",
      offset: { dx, dy },
    },
  };

  return reframe;
}
