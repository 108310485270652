import { createCanvas, freeCanvas } from "canvas-utils";
import { AnyCanvasRenderingContext2D, CaptionEngine, CaptionVideoArea } from "captions-engine";

import { MICROSECONDS_IN_SECOND } from "~/utils/timestamp-formatter";

import { createCaptionEngine } from "../createCaptionEngine";
import { drawSelectionBox } from "../selectionDrawing";

import { RenderOverlayParameters, RenderOverlayStatusType } from "./renderOverlay.types";

export async function renderOverlay({
  videoInfo,
  assets,
  countryCode,
  stylePreset,
  style,
  encoderFactory,
  onStatus,
  abortSignal,
  hideCaptions,
  isLandscape,
  trackCaptionEngineState,
  trackEncodedFrame,
}: RenderOverlayParameters): Promise<Blob> {
  const captionPages = hideCaptions ? [] : assets.captionPages ?? [];
  const images = assets.images ?? [];
  abortSignal?.throwIfAborted();
  const start = Date.now();
  const videoArea: CaptionVideoArea = { x: 0, y: 0, ...videoInfo };
  const encoder = encoderFactory(videoInfo.width, videoInfo.height);
  let captionEngine: CaptionEngine;
  try {
    captionEngine = createCaptionEngine();
    trackCaptionEngineState?.("created_caption_engine", "success");
  } catch (error) {
    trackCaptionEngineState?.(
      "created_caption_engine",
      "error",
      error instanceof Error ? error.message : "error creating caption engine"
    );
    throw error;
  }
  let drawingTime = 0;
  let encodingTime = 0;
  try {
    captionEngine.setArea(videoArea.width, videoArea.height, videoArea.scale);
    captionEngine.setImages(images);
    await captionEngine.setCaptions(captionPages, countryCode, isLandscape);
    await captionEngine.setTransitions(assets.transitions);
    await captionEngine.setWatermark(assets.watermark ?? null);
    captionEngine.setStyle(stylePreset, style);
    trackCaptionEngineState?.("set_caption_engine_properties", "success");
    const allTimestamps = captionEngine.getSynchronizedTimestamps(
      videoInfo.fps,
      videoInfo.duration
    );
    if (onStatus) {
      onStatus({ type: RenderOverlayStatusType.started });
    }
    encoder.registerFrameEncodedCallback((frameId) => {
      if (onStatus) {
        onStatus({
          type: RenderOverlayStatusType.renderingFrames,
          currentFrame: frameId,
          totalFrames: allTimestamps.length,
        });
      }
      if (trackEncodedFrame) {
        const frameId25Percent = Math.floor(allTimestamps.length * 0.25);
        const frameId50Percent = Math.floor(allTimestamps.length * 0.5);
        const frameId75Percent = Math.floor(allTimestamps.length * 0.75);

        if (frameId === frameId25Percent) {
          trackEncodedFrame("encoded_frame_25", "success");
        } else if (frameId === frameId50Percent) {
          trackEncodedFrame("encoded_frame_50", "success");
        } else if (frameId === frameId75Percent) {
          trackEncodedFrame("encoded_frame_75", "success");
        } else if (frameId === allTimestamps.length - 1) {
          trackEncodedFrame("encoded_frame_100", "success");
        }
      }
    });

    for (const timestampS of allTimestamps) {
      abortSignal?.throwIfAborted();
      const timestampUs = timestampS * MICROSECONDS_IN_SECOND;
      const captionCanvas = createCanvas(videoInfo.width, videoInfo.height);
      try {
        // Explicit type cast to workaround type misdetection
        const captionCtx = captionCanvas.getContext("2d") as AnyCanvasRenderingContext2D | null;
        if (!captionCtx) {
          throw new Error("Failed to get Canvas contexts");
        }
        // Draws the captions
        const startDrawing = Date.now();
        await captionEngine.setContext(captionCtx);
        await captionEngine.setTimestamp(timestampS);
        await captionEngine.draw(0, 0);
        const endDrawing = Date.now();
        drawingTime += endDrawing - startDrawing;
        // Adds the resulting image to the APNG file
        const startEncoding = Date.now();
        await encoder.addFrame(captionCtx, timestampUs);
        const endEncoding = Date.now();
        encodingTime += endEncoding - startEncoding;
      } catch (error) {
        trackCaptionEngineState?.(
          "set_caption_engine_context",
          "error",
          error instanceof Error ? error.message : "error setting caption engine context"
        );
        throw error;
      } finally {
        freeCanvas(captionCanvas);
      }
    }
    if (onStatus) {
      onStatus({ type: RenderOverlayStatusType.finalizing });
    }
    const buffer = await encoder.getEncoded(videoInfo.duration * MICROSECONDS_IN_SECOND);
    abortSignal?.throwIfAborted();
    return new Blob([buffer], { type: "image/apng" });
  } catch (error) {
    trackCaptionEngineState?.(
      "set_caption_engine_properties",
      "error",
      error instanceof Error ? error.message : "error setting caption engine properties"
    );
    throw error;
  } finally {
    encoder.terminate();
    captionEngine.destroy();
    const end = Date.now();
    console.log(
      `Total rendering time: ${
        end - start
      } ms\nTime spent drawing: ${drawingTime} ms\nTime spent waiting for the encoder: ${encodingTime} ms`
    );
  }
}

export const renderSelectionBox = drawSelectionBox;
