import { Transition } from "~/database/transition.types";
import { Clip } from "~/utils/videoClips";

/**
 * @param transitions list of Transition objects describing the transitions between video clips
 * @param existingShots list of Clip objects describing the video clips that already exist
 * @returns list of Clip objects that contain the provided transitions and existingShots merged together
 */
export function convertTransitionsToShots(
  transitions: Transition[],
  existingShots: Clip[] | undefined
): Clip[] {
  const shots: Clip[] = [];
  transitions.forEach((transition, index) => {
    if (index === transitions.length - 1) {
      return;
    }

    shots.push({
      startTime: transition.startTime,
      endTime: transitions[index + 1].startTime,
    });
  });
  shots.forEach((clip, index) => {
    const isLastClip = index === shots.length - 1;
    const isClipInsideDeletedClip = existingShots?.some((currentClip) => {
      if (isLastClip) {
        return currentClip.startTime <= clip.startTime && currentClip.deleted;
      }
      return (
        currentClip.startTime <= clip.startTime &&
        currentClip.endTime >= clip.endTime &&
        currentClip.deleted
      );
    });
    if (isClipInsideDeletedClip) {
      clip.deleted = true;
    }
  });
  return shots;
}
