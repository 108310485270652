import type {
  AnyCanvasRenderingContext2D,
  CaptionStyle,
  CaptionStylePreset,
  ImageItem,
  TransitionAnimation,
  Watermark,
} from "captions-engine";

import { STATUS_SUCCESS, STATUS_ERROR } from "~/constants/mixpanel.constants";

import { CaptionPage } from "../captionProcessing";
import { Point2D } from "../positioningUtils";

export interface OverlayEncoder {
  addFrame(canvasCtx: AnyCanvasRenderingContext2D, timestampUs: number): Promise<void>;
  registerFrameEncodedCallback(callback: (frameId: number) => void): void;
  getEncoded(durationUs: number): Promise<ArrayBuffer>;
  terminate(): void;
}

export enum RenderOverlayStatusType {
  started,
  renderingFrames,
  finalizing,
}

export interface RenderOverlayEventStatus {
  type: RenderOverlayStatusType.started | RenderOverlayStatusType.finalizing;
}

export interface RenderOverlayProgressStatus {
  type: RenderOverlayStatusType.renderingFrames;
  currentFrame: number;
  totalFrames: number;
  lastFramePreview?: HTMLCanvasElement | ImageBitmap | OffscreenCanvas;
}

export type RenderOverlayStatus = RenderOverlayEventStatus | RenderOverlayProgressStatus;

export type RenderOverlayStatusCallback = (status: RenderOverlayStatus) => void;

export interface RenderOverlayAssets {
  captionPages?: CaptionPage[] | null;
  transitions: TransitionAnimation[];
  images?: ImageItem[] | null;
  watermark?: Watermark | null;
}

export interface RenderOverlayVideoInfo {
  width: number;
  height: number;
  duration: number;
  fps: number;
  scale: Point2D;
}

export interface RenderOverlayParameters {
  videoInfo: RenderOverlayVideoInfo;
  assets: RenderOverlayAssets;
  countryCode: string;
  stylePreset: CaptionStylePreset;
  style: CaptionStyle;
  onStatus?: RenderOverlayStatusCallback;
  abortSignal?: AbortSignal;
  encoderFactory: (width: number, height: number) => OverlayEncoder;
  hideCaptions?: boolean;
  isLandscape?: boolean;
  trackCaptionEngineState?: (
    captionEngineState:
      | "created_caption_engine"
      | "set_caption_engine_properties"
      | "set_caption_engine_context",
    status: typeof STATUS_SUCCESS | typeof STATUS_ERROR,
    error?: string
  ) => void;
  trackEncodedFrame?: (
    encodedFramePercent:
      | "encoded_frame_25"
      | "encoded_frame_50"
      | "encoded_frame_75"
      | "encoded_frame_100",
    status: typeof STATUS_SUCCESS | typeof STATUS_ERROR,
    error?: string
  ) => void;
}
