/**
 * Raw signature that has to be at the start of a PNG file.
 */
export const pngSignature = Uint8Array.of(0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a);

let _crcTable: number[] | null = null;

/**
 * Determines the partial CRC value for a given byte while encoding a PNG file.
 *
 * @param byteValue - the raw byte value of the data buffer.
 * @return the partial CRC value.
 */
function getByteCRC(byteValue: number): number {
  _crcTable ??= calculateCrcTable();
  return _crcTable[byteValue];
}

/**
 * Calculates the CRC table for PNG file encoding.
 *
 * @return the CRC table.
 */
function calculateCrcTable(): number[] {
  const table = Array<number>(256);
  let c = 0;
  for (let n = 0; n < 256; n++) {
    c = n;
    for (let k = 0; k < 8; k++) {
      if (c & 1) {
        c = 0xedb88320 ^ (c >>> 1);
      } else {
        c = c >>> 1;
      }
    }
    table[n] = c;
  }
  return table;
}

/**
 * Calculates the CRC value of a raw data buffer while encoding a PNG file.
 *
 * Note: while encoding a PNG chunk the buffer should only contain the data the
 * CRC value should be calculated for, therefore not including the byte length
 * field in the beginning.
 *
 * @param buffer - the raw data to calculate the CRC for.
 * @return the CRC value for the raw binary data.
 */
export function calculateCrc(buffer: Uint8Array): number {
  const result = buffer.reduce((crc, value) => {
    return getByteCRC((crc ^ value) & 0xff) ^ (crc >>> 8);
  }, 0xffffffff);
  return result ^ 0xffffffff;
}

/**
 * Encodes a PNG chunk containing raw data, filling out the length and the CRC
 * fields.
 *
 * @param type - the chunk's type identifier, which should contain four
 * characters.
 * @param data - the raw chunk data.
 * @return the PNG chunk containing the given data.
 */
export function buildPngChunk(type: string, data?: ArrayBuffer): ArrayBuffer;
/**
 * Encodes a PNG chunk containing raw data, filling out the length and the CRC
 * fields.
 *
 * @param type - the chunk's type identifier, which should contain four
 * characters.
 * @param length - the length of the chunk's contained data.
 * @param fillData - callback which receives the DataView pointing to the
 * allocated buffer's data region.
 * @return the PNG chunk containing the data set by the callback.
 */
export function buildPngChunk(
  type: string,
  length: number,
  fillData: (view: DataView) => void
): ArrayBuffer;
export function buildPngChunk(
  type: string,
  data?: ArrayBuffer | number,
  fillData?: (view: DataView) => void
): ArrayBuffer {
  if (type.length !== 4) {
    throw new Error("Wrong PNG chunk type descriptor");
  }
  if (typeof data === "number" && !fillData) {
    throw new Error("Missing fillData callback!");
  }
  /*
    The PNG chunk is composed of:
    4 bytes - length of the data section in bytes (n)
    4 bytes - the chunk type (ASCII)
    n bytes - the data section
    4 bytes - the CRC value (calculated from offset 8 until the CRC value itself)
   */
  const dataLength = typeof data === "number" ? data : data?.byteLength ?? 0;
  const result = new ArrayBuffer(8 + dataLength + 4);
  const lengthView = new DataView(result, 0, 4);
  const signatureView = new DataView(result, 4, 4);
  const crcView = new DataView(result, 8 + dataLength, 4);
  signatureView.setUint8(0, type.charCodeAt(0));
  signatureView.setUint8(1, type.charCodeAt(1));
  signatureView.setUint8(2, type.charCodeAt(2));
  signatureView.setUint8(3, type.charCodeAt(3));
  lengthView.setUint32(0, dataLength, false);
  if (typeof data === "number") {
    if (dataLength > 0 && fillData) {
      const dataView = new DataView(result, 8, dataLength);
      fillData(dataView);
    }
  } else if (data) {
    new Uint8Array(result).set(new Uint8Array(data), 8);
  }
  const crc = calculateCrc(new Uint8Array(result, 4, 4 + dataLength));
  crcView.setUint32(0, crc, false);
  return result;
}

/**
 * Scans a raw PNG data buffer and finds all chunks of the specified type.
 *
 * @param data - the raw data buffer containing the PNG file.
 * @param type - the PNG chunk type that will be searched for.
 * @return an array of the data buffers of all found chunks.
 */
export function findPngChunk(data: ArrayBuffer, type: string) {
  let offset = 0;
  const result: ArrayBuffer[] = [];
  // optionally skips the PNG signature
  if (
    new Uint8Array(data, 0, pngSignature.length).every(
      (value, index) => value === pngSignature[index]
    )
  ) {
    offset = offset + pngSignature.length;
  }
  // checks for offset + 7 as the signature is on [offset + 4 : offset + 7]
  while (offset + 7 < data.byteLength) {
    const headerView = new DataView(data, offset, 8);
    // Gets the data section length from the first 4 bytes
    const length = headerView.getUint32(0, false);
    // Calculates the total chunk size as:
    //  length field (4 bytes) + type field (4 bytes) + data section (length) + crc field (4 bytes)
    const chunkSize = 8 + length + 4;
    if (
      headerView.getUint8(4) === type.charCodeAt(0) &&
      headerView.getUint8(5) === type.charCodeAt(1) &&
      headerView.getUint8(6) === type.charCodeAt(2) &&
      headerView.getUint8(7) === type.charCodeAt(3)
    ) {
      const newBuff = new ArrayBuffer(chunkSize);
      const newBuffView = new Uint8Array(newBuff);
      newBuffView.set(new Uint8Array(data, offset, chunkSize));
      result.push(newBuff);
    }
    // Moves the offset to the next chunk
    offset = offset + chunkSize;
  }
  return result;
}
