export const useClickWithoutFocus = () => {
  const handleClickWithoutTriggeringFocus = (event: React.MouseEvent<HTMLElement>) => {
    const isEventFromKeyboard = event.screenX === 0 && event.screenY === 0;
    if (!isEventFromKeyboard) {
      event.currentTarget.blur();
    }
  };

  return {
    handleClickWithoutTriggeringFocus,
  };
};
