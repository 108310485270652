import { useCallback, useContext, useEffect, useMemo } from "react";

import { ReframeContext } from "~/context/ReframeContext";
import {
  getReframeOperations,
  getTargetAspectRatioNumber,
  getTargetSize,
  isBasicReframe,
  isSplitReframe,
  ShotReframeOptions,
} from "~/utils/reframing";

import { lerp } from "../utils/lerp";

import { ProjectPersistence } from "./useProjectPersistence";

interface Offset {
  dx: number;
  dy: number;
}

interface Rect {
  x: number;
  y: number;
  w: number;
  h: number;
}

interface BoxCoordinates {
  l: number;
  t: number;
  r: number;
  b: number;
}

export interface VideoDrawPixelMapping {
  /**
   * Rect describing the area of the video image that should be drawn.
   * These coordinates are normalized between 0 and 1, where 1 represents the width or height
   * of the image.
   */
  from: BoxCoordinates;
  /**
   * Rect describing the area of target rect the image should be drawn onto.
   * These coordinates are normalized between 0 and 1, where 1 represents the width or height
   * of the draw area.
   */
  to: BoxCoordinates;
}

export interface VideoDrawInfo {
  /**
   * The offset of the video within the draw area.
   * This value is normalized between -1 and 1, where either represents offseting the image by
   * its full width or height.
   */
  offset: Offset;
  /**
   * The area where the video image should be drawn.
   * These coordinates are in absolute pixels and are not normalized.
   */
  drawRect: Rect;
  /**
   * The pixel mapping for the video image.
   */
  pixelMappings: VideoDrawPixelMapping[];
}

export function useReframe(projectPersistence?: ProjectPersistence) {
  const {
    targetAspect,
    setTargetAspect,
    containToCover,
    setContainToCover,
    originalWidth,
    originalHeight,
    setOriginalVideoSize,
    offset,
    setOffset,
  } = useContext(ReframeContext);

  // initialize the reframe state from project
  useEffect(() => {
    const reframeTarget = projectPersistence?.project?.reframeTarget;
    if (!projectPersistence?.loaded || !reframeTarget) {
      return;
    }
    const { targetAspect, offset, containToCover } = reframeTarget;

    setTargetAspect(targetAspect);
    setOffset(offset);
    setContainToCover(containToCover);
  }, [projectPersistence?.loaded]);

  // update project data on any reframe data changes
  useEffect(() => {
    if (!projectPersistence?.loaded) {
      return;
    }
    const reframeTarget = {
      targetAspect,
      offset,
      containToCover,
    };

    projectPersistence.updateProject({ reframeTarget });
  }, [targetAspect, offset.dx, offset.dy, containToCover]);

  // the original aspect ratio
  const originalAspect = useMemo(
    () => originalWidth / originalHeight,
    [originalWidth, originalHeight]
  );

  // convert the aspect enum into a numerical aspect ratio
  const targetAspectNum = useMemo(() => {
    return getTargetAspectRatioNumber(targetAspect) ?? originalAspect;
  }, [targetAspect, originalAspect]);

  // is the same side side of 1:1 aspect ratio
  // we keep any portrait in its original portrait
  // and any landscape in its original landscape
  const isSameAspect = useMemo(() => {
    if (originalAspect < 1 && targetAspectNum < 1) {
      return true;
    }
    if (originalAspect > 1 && targetAspectNum > 1) {
      return true;
    }
    if (originalAspect === 1 && targetAspectNum === 1) {
      return true;
    }

    return false;
  }, [originalAspect, targetAspectNum]);

  // given a container rect, calculate the rect that the target reframe will be
  const getTargetArea = useCallback(
    (containerRect: Rect) => {
      const { w, h } = containerRect;

      const containerAspect = w / h;
      const widthConstrained = containerAspect > targetAspectNum;

      if (widthConstrained) {
        return {
          x: (w - targetAspectNum * h) / 2,
          y: 0,
          w: targetAspectNum * h,
          h,
        };
      }

      return {
        x: 0,
        y: (h - w / targetAspectNum) / 2,
        w,
        h: w / targetAspectNum,
      };
    },
    [targetAspectNum]
  );

  const { targetWidth, targetHeight } = getTargetSize(
    targetAspectNum,
    originalWidth,
    originalHeight
  );

  //TODO: Add description (POC)
  const getVideoDrawInfo = useCallback(
    (
      containerRect: Pick<Rect, "h" | "w">,
      targetAreaRect: Rect,
      overrides?: ShotReframeOptions
    ): VideoDrawInfo => {
      if (isSplitReframe(overrides)) {
        const operations = getReframeOperations({
          options: overrides,
          originalWidth,
          originalHeight,
          targetWidth: targetAreaRect.w,
          targetHeight: targetAreaRect.h,
        });
        const pixelMappings: VideoDrawPixelMapping[] = operations.map((op) => {
          const from = {
            l: op.src.x / originalWidth,
            t: op.src.y / originalHeight,
            r: (op.src.x + op.src.width) / originalWidth,
            b: (op.src.y + op.src.height) / originalHeight,
          };
          const to = {
            l: op.dest.x / targetWidth,
            t: op.dest.y / targetHeight,
            r: (op.dest.x + op.dest.width) / targetWidth,
            b: (op.dest.y + op.dest.height) / targetHeight,
          };
          return { from, to };
        });

        return {
          drawRect: targetAreaRect,
          offset: {
            dx: 0,
            dy: 0,
          },
          pixelMappings,
        };
      }
      const aspectFactor = targetAspectNum / (originalWidth / originalHeight);
      const scaleX = targetAreaRect.w / originalWidth;
      const scaleY = targetAreaRect.h / originalHeight;
      const scaleToCover = Math.max(scaleX, scaleY);
      const scaleToFit = Math.min(scaleX, scaleY);
      const containToCoverOverride =
        overrides?.fitOrFill === "fit" ? 0 : overrides?.fitOrFill === "fill" ? 1 : containToCover;
      const scale = lerp(scaleToFit, scaleToCover, containToCoverOverride);

      // Override dx/dy on a per-shot basis
      let { dx, dy } = isBasicReframe(overrides) ? overrides.layout.offset : offset;

      const outputWidth = originalWidth * scale;
      const outputHeight = originalHeight * scale;

      // enforce crop limits again
      let offX = 0;
      let offY = 0;
      if (aspectFactor < 1) {
        const dxLimit = 0.5 - targetAreaRect.w / outputWidth / 2;
        dx = Math.min(dxLimit, Math.max(-dxLimit, dx));
        offX = dx * outputWidth;
      } else {
        const dyLimit = 0.5 - targetAreaRect.h / outputHeight / 2;
        dy = Math.min(dyLimit, Math.max(-dyLimit, dy));
        offY = dy * outputHeight;
      }
      return {
        drawRect: {
          x: offX + (containerRect.w - outputWidth) / 2,
          y: offY + (containerRect.h - outputHeight) / 2,
          w: outputWidth,
          h: outputHeight,
        },
        offset: {
          dx,
          dy,
        },
        pixelMappings: [],
      };
    },
    [
      originalWidth,
      originalHeight,
      targetWidth,
      targetHeight,
      targetAspectNum,
      offset.dx,
      offset.dy,
      containToCover,
    ]
  );

  // given container and target rects, calculate a css transform to place the video correctly
  // assumes that the video is already sized to fit within the container
  const getVideoTransform = useCallback(
    (containerRect: Rect, targetAreaRect: Rect, overrides?: ShotReframeOptions) => {
      const aspectFactor = targetAspectNum / (originalWidth / originalHeight); // todo simplify

      const scale0 = Math.min(containerRect.w / originalWidth, containerRect.h / originalHeight);

      const scaleCover =
        Math.max(targetAreaRect.w / originalWidth, targetAreaRect.h / originalHeight) / scale0;
      const scaleFit =
        Math.min(targetAreaRect.w / originalWidth, targetAreaRect.h / originalHeight) / scale0;

      // Override fit/fill on a per-shot basis
      // Later this entire logic will move inside WebGL instead of CSS transform
      const containToCoverOverride =
        overrides?.fitOrFill === "fit" ? 0 : overrides?.fitOrFill === "fill" ? 1 : undefined;

      const alpha = containToCoverOverride ?? containToCover;
      const scale = alpha * scaleCover + (1 - alpha) * scaleFit;

      // Override dx/dy on a per-shot basis
      const dx = isBasicReframe(overrides) ? overrides.layout.offset.dx : offset.dx;
      const dy = isBasicReframe(overrides) ? overrides.layout.offset.dy : offset.dy;

      // enforce crop limits again
      let offX = 0;
      let offY = 0;
      if (aspectFactor < 1) {
        const scaledWidth = originalWidth * scale0;
        const dxLimit = 0.5 - targetAreaRect.w / scaledWidth / scale / 2;
        offX = Math.min(dxLimit, Math.max(-dxLimit, dx)) * scaledWidth;
      } else {
        const scaledHeight = originalHeight * scale0;
        const dyLimit = 0.5 - targetAreaRect.h / scaledHeight / scale / 2;
        offY = Math.min(dyLimit, Math.max(-dyLimit, dy)) * scaledHeight;
      }

      const reframe = {
        dstWidth: targetAreaRect.w,
        dstHeight: targetAreaRect.h,
        scale,
        cropCoordX: Math.round((containerRect.w * scale) / 2 - targetAreaRect.w / 2 - offX * scale),
        cropCoordY: Math.round((containerRect.h * scale) / 2 - targetAreaRect.h / 2 - offY * scale),
      };

      return {
        scaleToView: scale * scale0,
        scaledOffX: scale * offX,
        scaledOffY: scale * offY,
        reframe,
      };
    },
    [originalWidth, originalHeight, targetAspectNum, offset.dx, offset.dy, containToCover]
  );

  const { reframe } = getVideoTransform(
    { x: 0, y: 0, w: originalWidth, h: originalHeight },
    {
      x: 0,
      y: 0,
      w: targetWidth,
      h: targetHeight,
    }
  );

  return {
    reframe,
    targetAspect,
    targetAspectNum,
    targetWidth,
    targetHeight,
    setTargetAspect,
    originalAspect,
    isSameAspect,
    containToCover,
    setContainToCover,
    originalWidth,
    originalHeight,
    setOriginalVideoSize,
    offset,
    setOffset,
    getTargetArea,
    getVideoTransform,
    getVideoDrawInfo,
    isLandscape: targetAspectNum > 1,
  };
}

export type ReframeParams = ReturnType<typeof useReframe>;
