import { useState, createContext, type PropsWithChildren, SetStateAction, Dispatch } from "react";

import { Aspect } from "~/database/database.types";

interface Offset {
  dx: number;
  dy: number;
}

interface ReframeContextData {
  targetAspect: Aspect;
  setTargetAspect: Dispatch<SetStateAction<Aspect>>;
  originalAspect: number;
  setOriginalAspect: Dispatch<SetStateAction<number>>;
  containToCover: number;
  setContainToCover: Dispatch<SetStateAction<number>>;
  originalWidth: number;
  originalHeight: number;
  setOriginalVideoSize: Dispatch<SetStateAction<[number, number]>>;
  offset: Offset;
  setOffset: Dispatch<SetStateAction<Offset>>;
}

export const ReframeContext = createContext<ReframeContextData>({
  targetAspect: "original",
  setTargetAspect: () => {},
  originalAspect: 1,
  setOriginalAspect: () => {},
  containToCover: 1,
  setContainToCover: () => {},
  originalWidth: 0,
  originalHeight: 0,
  setOriginalVideoSize: () => {},
  offset: { dx: 0, dy: 0 },
  setOffset: () => {},
});

export function ReframeProvider({ children, ...props }: PropsWithChildren) {
  const [targetAspect, setTargetAspect] = useState<Aspect>("original");
  const [originalAspect, setOriginalAspect] = useState<number>(1);
  const [containToCover, setContainToCover] = useState<number>(1);
  const [originalVideoSize, setOriginalVideoSize] = useState<[number, number]>([0, 0]);
  const [offset, setOffset] = useState<Offset>({ dx: 0, dy: 0 });

  return (
    <ReframeContext.Provider
      value={{
        targetAspect,
        setTargetAspect,
        originalAspect,
        setOriginalAspect,
        containToCover,
        setContainToCover,
        originalWidth: originalVideoSize[0],
        originalHeight: originalVideoSize[1],
        setOriginalVideoSize,
        offset,
        setOffset,
      }}
      {...props}
    >
      {children}
    </ReframeContext.Provider>
  );
}
