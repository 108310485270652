import type { AnyCanvasRenderingContext2D } from "captions-engine";

import { OverlayEncoder } from "../renderOverlay";

export type APngFrameEncodedHandler = (frameId: number) => void;

export abstract class APngEncoder implements OverlayEncoder {
  onFrameArrived: APngFrameEncodedHandler | null = null;
  readonly width: number;
  readonly height: number;

  protected constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  registerFrameEncodedCallback(callback: (frameId: number) => void): void {
    this.onFrameArrived = callback;
  }

  abstract addFrame(frameImage: AnyCanvasRenderingContext2D, timestampUs: number): Promise<void>;
  abstract getEncoded(durationUs: number): Promise<Uint8Array>;
  abstract terminate(): void;
}
