import {
  cropImage,
  encodeImageAsPNG,
  getDifferenceArea,
  getImageData,
  getImageSize,
  getNonTransparentArea,
  SupportedImageSource,
} from "canvas-utils";

import { buildPngChunk, calculateCrc, findPngChunk, pngSignature } from "./pngUtils";

const MICROSECONDS_IN_SECOND = 1e6;
const MILLISECONDS_IN_SECOND = 1e3;

/**
 * Encodes a sequence of frames as an APNG animation file.
 */
export class APngBuilder {
  numPlays = 1;
  private frames: ArrayBuffer[] = [];
  private currentTimestamp = 0;
  private sequenceNumber = 0;
  private previousFrameImageData: ImageData | null = null;
  readonly width: number;
  readonly height: number;

  /**
   * Constructs a new APngEncoder instance representing an animation with a
   * given width and height.
   *
   * @param width - the overall animation width in pixels.
   * @param height - the overall animation height in pixels.
   */
  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  /**
   * Encodes a new frame as part of an APNG animation. Will insert empty frames
   * as necessary and crop the image to the difference between adjacent frames
   * to reduce the total file size,
   *
   * @param image - the image to be encoded.
   * @param timestampUs - the timestamp in microseconds.
   */
  async addFrame(image: SupportedImageSource, timestampUs: number): Promise<void> {
    if (timestampUs < this.currentTimestamp) {
      throw new Error("Frames must be given at monotonically increasing timestamps");
    }
    const delayDen = MILLISECONDS_IN_SECOND;
    const isFirstFrame = this.frames.length === 0;
    // Compares it to delayDen / 2 so frames with timestamps that round to 0
    // will still set isAtTimeZero to true
    const isAtTimeZero = timestampUs <= delayDen / 2;
    if (isFirstFrame && !isAtTimeZero) {
      // needs to insert a blank first frame
      const blankFrameImage = new ImageData(this.width, this.height);
      await this.doAddImage(blankFrameImage, 0, 0, true, false);
    }
    const delay = timestampUs - this.currentTimestamp;
    let delayNum = await this.maybeAddEmptyFrames(
      delay / (MICROSECONDS_IN_SECOND / delayDen),
      delayDen
    );
    delayNum = Math.floor(delayNum);
    const imageData = getImageData(image);
    if (this.frames.length === 0) {
      // The first frame must match the total animation dimensions.
      await this.doAddImage(imageData, 0, 0, true, false);
      this.previousFrameImageData = getImageData(image);
    } else if (!this.previousFrameImageData) {
      const cropTo = getNonTransparentArea(imageData);
      const croppedImage = cropImage(imageData, cropTo.x, cropTo.y, cropTo.width, cropTo.height);
      this.setLastFrameDelay(delayNum, delayDen);
      await this.doAddImage(croppedImage, cropTo.x, cropTo.y, true, false);
      this.previousFrameImageData = getImageData(image);
    } else {
      const cropTo = getDifferenceArea(imageData, this.previousFrameImageData);
      if (cropTo.width === 0 && cropTo.height === 0) {
        return;
      }
      const croppedImage = cropImage(imageData, cropTo.x, cropTo.y, cropTo.width, cropTo.height);
      this.setLastFrameDelay(delayNum, delayDen);
      await this.doAddImage(croppedImage, cropTo.x, cropTo.y, false, false);
      this.previousFrameImageData = imageData;
    }
  }

  /**
   * Adds empty frames to pad the APNG animation if the given delay overflows
   * the two-byte denominator. The total delay in seconds is given as
   * delayNum/delayDen.
   *
   * @param delayNum - the delay numerator.
   * @param delayDen - the delay denominator.
   * @return the remaining delay numerator guaranteed to not overflow.
   * @private
   */
  private async maybeAddEmptyFrames(delayNum: number, delayDen: number): Promise<number> {
    let newDelayNum = delayNum;
    const blankFrameDelayNum = 10000;
    while (newDelayNum > 65535) {
      this.setLastFrameDelay(blankFrameDelayNum, delayDen);
      await this.doAddImage(new ImageData(1, 1), 0, 0, false, true);
      newDelayNum = newDelayNum - blankFrameDelayNum;
    }
    return newDelayNum;
  }

  /**
   * Sets the delay of the last shown frame. The delay in seconds is given as
   * delayNum/delayDen. It does so by modifying the last frame's fcTL.
   *
   * @param delayNum - the delay numerator
   * @param delayDen - the delay denominator
   * @private
   * @see {@link https://www.w3.org/TR/png/#animation-information|Portable Network Graphics (PNG) Specification}
   */
  private setLastFrameDelay(delayNum: number, delayDen: number) {
    if (this.frames.length > 0) {
      // View of the previous fcTL data
      const prevFctlView = new DataView(this.frames[this.frames.length - 1], 8, 30);
      prevFctlView.setUint16(20, delayNum, false);
      prevFctlView.setUint16(22, delayDen, false);
      const prevFctlData = new Uint8Array(this.frames[this.frames.length - 1], 4, 30);
      const newCrc = calculateCrc(prevFctlData);
      prevFctlView.setUint32(26, newCrc, false);
      this.currentTimestamp =
        this.currentTimestamp + (delayNum * MICROSECONDS_IN_SECOND) / delayDen;
    }
  }

  /**
   * Internal method to add an image to the APNG animation. Assumes the delays
   * and padding frames were already handled beforehand. Will create the new
   * frame's fcTL (frame control) chunk, encode the image as a PNG file and
   * convert the static PNG data to a vector of fdAT (frame data) chunks.
   *
   * @param image - the image to be encoded and inserted
   * @param x - the x position the image should be drawin during the animation
   * @param y - the y position the image should be drawin during the animation
   * @param erase - whether the area should be erased after showing the frame
   * @param blend - whether the image should be drawn blending or replacing
   * the area
   * @see {@link https://www.w3.org/TR/png/#animation-information|Portable Network Graphics (PNG) Specification}
   * @private
   */
  private async doAddImage(
    image: SupportedImageSource,
    x: number,
    y: number,
    erase: boolean,
    blend: boolean
  ): Promise<void> {
    if (!erase && this.frames.length > 0) {
      const prevFctlView = new DataView(this.frames[this.frames.length - 1], 8, 30);
      prevFctlView.setUint8(24, 0); // dispose_op = NONE
      const prevFctlData = new Uint8Array(this.frames[this.frames.length - 1], 4, 30);
      const newCrc = calculateCrc(prevFctlData);
      prevFctlView.setUint32(26, newCrc, false);
    }
    const { width, height } = getImageSize(image);
    const fctl = buildPngChunk("fcTL", 26, (view) => {
      view.setUint32(0, this.sequenceNumber, false);
      view.setUint32(4, width, false);
      view.setUint32(8, height, false);
      view.setUint32(12, x, false);
      view.setUint32(16, y, false);
      view.setUint16(20, 0, false);
      view.setUint16(22, 0, false);
      view.setUint8(24, 1); // dispose_op = BACKGROUND
      view.setUint8(25, blend ? 1 : 0); // blend_op = blend ? OVER : SOURCE
    });
    this.sequenceNumber = this.sequenceNumber + 1;
    const framePng = await encodeImageAsPNG(image);
    const framesIDAT = findPngChunk(framePng, "IDAT");
    const fdats: ArrayBuffer[] = [];
    if (this.frames.length > 0) {
      for (const frameIDAT of framesIDAT) {
        const idatLen = new DataView(frameIDAT, 0, 4).getUint32(0, false);
        const fdatData = new ArrayBuffer(idatLen + 4);
        new DataView(fdatData, 0, 4).setUint32(0, this.sequenceNumber, false);
        new Uint8Array(fdatData, 4, idatLen).set(new Uint8Array(frameIDAT, 8, idatLen));
        fdats.push(buildPngChunk("fdAT", fdatData));
        this.sequenceNumber = this.sequenceNumber + 1;
      }
    } else {
      // first frame uses IDATs rather than fdATs
      fdats.push(...framesIDAT);
    }
    const fdatsLen = fdats.reduce((prev, current) => prev + current.byteLength, 0);
    const fullFrameData = new ArrayBuffer(fctl.byteLength + fdatsLen);
    const frameDataU8 = new Uint8Array(fullFrameData);
    frameDataU8.set(new Uint8Array(fctl), 0);
    let setIdx = fctl.byteLength;
    for (const fdat of fdats) {
      frameDataU8.set(new Uint8Array(fdat), setIdx);
      setIdx = setIdx + fdat.byteLength;
    }
    this.frames.push(fullFrameData);
  }

  /**
   * Finishes the encoding process and returns the complete animation's raw
   * data.
   *
   * @param durationUs - the total running time of the animation in
   * microseconds
   * @return the encoded animation
   * @see {@link https://www.w3.org/TR/png/#apng-frame-based-animation|Portable Network Graphics (PNG) Specification}
   */
  async getEncoded(durationUs: number): Promise<Uint8Array> {
    // Pads the video to the total duration
    if (durationUs < this.currentTimestamp) {
      throw new Error("The given duration doesn't contain all encoded frames!");
    }
    if (this.frames.length === 0) {
      // needs to insert a blank first frame
      const blankFrameImage = new ImageData(this.width, this.height);
      await this.doAddImage(blankFrameImage, 0, 0, true, false);
    }
    const delayDen = MILLISECONDS_IN_SECOND;
    const delay = durationUs - this.currentTimestamp;
    const delayNum = await this.maybeAddEmptyFrames(
      delay / (MICROSECONDS_IN_SECOND / delayDen),
      delayDen
    );
    this.setLastFrameDelay(delayNum, delayDen);
    // Creates a list of all PNG chunks necessary
    const allBuffers: ArrayBuffer[] = [];
    allBuffers.push(pngSignature);
    allBuffers.push(this.buildIHDR());
    allBuffers.push(this.buildACTL());
    allBuffers.push(...this.frames);
    allBuffers.push(buildPngChunk("IEND"));
    // Concatenates the result
    const bufferSize = allBuffers.reduce((size, buff) => size + buff.byteLength, 0);
    const resultBuffer = new Uint8Array(bufferSize);
    let idx = 0;
    for (const buff of allBuffers) {
      const u8buff = new Uint8Array(buff);
      resultBuffer.set(u8buff, idx);
      idx = idx + u8buff.length;
    }
    return resultBuffer;
  }

  /**
   * Builds the main acTL (animation control) chunk.
   *
   * @return the built acTL chunk's raw data
   * @private
   * @see {@link https://www.w3.org/TR/png/#acTL-chunk|Portable Network Graphics (PNG) Specification}
   */
  private buildACTL(): ArrayBuffer {
    return buildPngChunk("acTL", 8, (view) => {
      view.setUint32(0, this.frames.length, false);
      view.setUint32(4, this.numPlays, false);
    });
  }

  /**
   * Builds the animation's main IHDR (header) chunk.
   *
   * @return the built ACTL chunk's raw data
   * @private
   * @see {@link https://www.w3.org/TR/png/#11IHDR|Portable Network Graphics (PNG) Specification}
   */
  private buildIHDR(): ArrayBuffer {
    return buildPngChunk("IHDR", 13, (view) => {
      view.setUint32(0, this.width, false);
      view.setUint32(4, this.height, false);
      view.setUint8(8, 8); // 8 bits per color channel
      view.setUint8(9, 6); // True color with alpha
      view.setUint8(10, 0); // Compression: deflate
      view.setUint8(11, 0); // Adaptative filtering
      view.setUint8(12, 0); // No interlace
    });
  }
}
