import { APngBuilder } from "apng-builder";
import type { AnyCanvasRenderingContext2D } from "captions-engine";

import { APngEncoder } from "./apngEncoder";

/**
 * Class that abstracts using the wrapper directly on the main thread for browsers
 * that do not support OffscreenCanvas.
 */
export class APngEncoderBuilderWrapper extends APngEncoder {
  private readonly encoder: APngBuilder;
  private lastEncodedFrame = -1;

  /**
   * Constructs a new ApngEncoderLegacyWrapper instance, which creates an APngEncoder object.
   *
   * @param width - the overall animation width in pixels.
   * @param height - the overall animation height in pixels.
   * @see APngEncoder.constructor
   */
  constructor(width: number, height: number) {
    super(width, height);
    this.encoder = new APngBuilder(width, height);
  }

  /**
   * Calls the APngEncoder to add a new frame and notifies the onFrameArrived callback on
   * completion.
   *
   * @param frameImage - the image to be encoded.
   * @param timestampUs - the timestamp in microseconds.
   * @see APngEncoder.addFrame
   */
  async addFrame(frameImage: AnyCanvasRenderingContext2D, timestampUs: number): Promise<void> {
    await this.encoder.addFrame(frameImage.canvas, timestampUs);
    this.lastEncodedFrame++;
    this.onFrameArrived?.(this.lastEncodedFrame);
  }

  /**
   * Calls the APngEncoder worker to finalize the animation and returns the encoded file.
   *
   * @param durationUs - the total running time of the animation in
   * microseconds.
   * @return the encoded animation.
   * @see APngEncoder.getEncoded
   */
  getEncoded(durationUs: number): Promise<Uint8Array> {
    return this.encoder.getEncoded(durationUs);
  }

  /**
   * Does nothing since the encoding isn't executing on a Web Worker
   */
  terminate(): void {
    // Not a worker, so nothing to terminate
  }
}
