import type { ProjectFaceData } from "~/modules/project/utils/faceData/face-data.types";
import type { Clip } from "~/utils/videoClips";

import { centerFace } from "./centerFace";
import type { ShotReframeOptions } from "./reframing.types";
import { splitShotHorizontal, splitShotVertical } from "./splitShot";

export const FACE_SPLIT_THRESHOLD = 1.5;
export const FACE_CENTER_THRESHOLD = 0.4;

/**
 * Gets the average number of faces per frame of a shot.
 *
 * @param shot - The shot to analyze.
 * @param faceData - The face data to use.
 * @returns The average number of faces per frame.
 */
export function getNumberOfFaces(shot: Clip, faceData: ProjectFaceData | undefined) {
  if (!faceData) {
    return 0;
  }

  let totalNumFaces = 0;
  let numFramesThisShot = 0;
  faceData.faceFrames.forEach((faces, i) => {
    const timestamp = i / faceData.fps;

    // Select only face timestamps within the shot
    // Both `faceData` and `shot` are using absolute timestamps
    const timestampWithinShot = timestamp >= shot.startTime && timestamp <= shot.endTime;
    if (!timestampWithinShot) {
      return;
    }

    numFramesThisShot++;
    totalNumFaces += faces.length;
  });

  return totalNumFaces / numFramesThisShot;
}

/**
 * Based on `faceData`, automatically apply either Center Face or Split Shot,
 * depending on the number of faces.
 */
export function autoReframe(args: {
  shot: Clip;
  faceData: ProjectFaceData | undefined;
  videoSize: {
    outputWidth: number;
    outputHeight: number;
    originalWidth: number;
    originalHeight: number;
    targetAspectNum: number;
  };
}): ShotReframeOptions | undefined {
  const { shot, faceData } = args;

  const averageNumFaces = getNumberOfFaces(shot, faceData);

  // Closer to 2+ faces on average -> split shot
  if (averageNumFaces >= FACE_SPLIT_THRESHOLD) {
    const isOriginalHorizontal = args.videoSize.originalWidth > args.videoSize.originalHeight;
    const isTargetVertical = args.videoSize.outputHeight > args.videoSize.outputWidth;
    if (isOriginalHorizontal && isTargetVertical) {
      return {
        fitOrFill: "fill",
        layout: {
          type: "split-vertical",
          rects: splitShotVertical(args),
        },
      };
    }

    const isOriginalVertical = args.videoSize.originalHeight > args.videoSize.originalWidth;
    const isTargetHorizontal = args.videoSize.outputWidth > args.videoSize.outputHeight;
    if (isOriginalVertical && isTargetHorizontal) {
      return {
        fitOrFill: "fill",
        layout: {
          type: "split-horizontal",
          rects: splitShotHorizontal(args),
        },
      };
    }

    // otherwise, fall through to center face
  }

  // Closer to 1 face on average -> center face
  if (averageNumFaces >= FACE_CENTER_THRESHOLD) {
    return centerFace(args);
  }

  // Closer to 0 faces on average -> no reframe
  return {
    fitOrFill: "fit",
    layout: { type: "none" },
  };
}

const autoReframeCache = new Map<string, ShotReframeOptions | undefined>();

/**
 * Auto reframe is an expensive operation, computing face data across
 * thousands of video frames. React's `useMemo` isn't granular enough to prevent
 * recalculating this every time, since it sees a changed object reference and
 * can't tell which elements have changed. This function manually memoizes calls
 * to `autoReframe` based on specific dependencies in the arguments.
 *
 * Can be used interchangeably with {@link autoReframe}
 */
export const autoReframeMemoized: typeof autoReframe = (args) => {
  const { shot, faceData, videoSize } = args;

  // Compute the change in aspect ratio
  const originalAspectRatio = videoSize.originalWidth / videoSize.originalHeight;
  const targetAspectRatio = videoSize.outputWidth / videoSize.outputHeight;
  const aspectRatioChange = targetAspectRatio / originalAspectRatio;

  // Construct a unique string hash that depends on specific arguments & conditions
  const hash = [
    shot.startTime,
    shot.endTime,
    Number(shot.deleted ?? false), // 0 or 1
    faceData?.faceFrames.length ?? 0, // just length, not diffing entire array
    aspectRatioChange, // combines original/target width/height into one number
    // fit/fill could be added here, but autoReframe is never called for "fit"
  ].join("-");

  // Example hash: 144.768-146.118-0-9869-0.31640625

  // The hash is invariant to changes in `shot.reframe`,
  // and also memoizes each shot individually,
  // preventing additional unecessary rerenders.

  if (!autoReframeCache.has(hash)) {
    const result = autoReframe(args);
    autoReframeCache.set(hash, result);
  }
  const cachedResult = autoReframeCache.get(hash);

  // Sanity check: Prevent cache from growing indefinitely
  if (autoReframeCache.size > 9999) {
    console.warn("[auto-reframe] clearing cache due to size limit");
    autoReframeCache.clear();
  }

  return cachedResult;
};
